import { API_URL } from '../../Constants/constants';
import axios from 'axios';
import Footer from '../Footer';
import Header from '../Header';
import React, { useState, useEffect } from 'react';
import GradeChart from './GradeChart';
import styled from 'styled-components';
import { refreshToken } from '../Auth/Authentication';
import { useNavigate } from 'react-router-dom';
import HistoryCards from './HistoryCards';
import ProfilePic from '../../assets/images/DefaultProfilePic.png'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';


function DashboardPage () {
    require('../../assets/css/Dashboard.css');
    require('../../assets/css/style-s3.css');
    const navigate = useNavigate();
    const [days, setDays] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
	const [data, setData] = useState(null);
	const [displayMock, setDisplayMock] = useState(false);
	const [displayGrade, setDisplayGrade] = useState(true);
	const [username, setUsername] = useState('User');

	const getIsAdmin = async () => {
		try {
			const response = await axios.get(API_URL + '/auth/is_admin', {
				headers: {
					'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
				}
			});
			setIsAdmin(response.data.is_admin);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				if (error.response.data.msg === 'Token has expired') {
					try {
						if (refreshToken()) {
							getIsAdmin();
							return;
						}
					} catch (e) {
						console.error('Error refreshing token:', e);
					}
				}
			}
			console.error('Error:', error);
		}
	};

  useEffect(() => {
    const newDays = [];
    for (let i = 0; i < 30; i++) {
      const level = Math.floor(Math.random() * 5);
      newDays.push(level);
    }
    setDays(newDays);
  }, []);

  useEffect(() => {
	setUsername(sessionStorage.getItem('username'));
  }, []);

    useEffect(() => {
        if (!sessionStorage.getItem('access')) {
            navigate('/login');
        }
		else {
			getIsAdmin();
		}
    }, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_URL + '/mock/get_all_graders', {
					headers: {
						'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
					}
				});
				if (response.status === 200) {
					console.log("AAA");
					console.log(response);
					setData(response.data);
				}
			} catch (error) {
				if (error.response && error.response.status === 401) {
					if (error.response.data.msg === 'Token has expired') {
						try {
							if (refreshToken()) {
								fetchData();
								return;
							}
						} catch (e) {
							console.error('Error refreshing token:', e);
						}
					}
				}
			  console.error('Error:', error);
			}
		}
		
		  // call the function
		  fetchData();
    }, []);

	const enableGrade = () => {
		setDisplayGrade(true);
		setDisplayMock(false);
	};
	
	// Add an event listener for the 'mockLink' anchor tag
	const enableMock = () => {
		setDisplayGrade(false);
		setDisplayMock(true);
	};
	

    return (
    <div className="ds-app">
	<Header></Header>
	<div className="ds-body">
		<div className="ds-body-navigation">
			<nav className="ds-navigation">
            <a className="ds-user-profile">
				<span>{username}</span>
				<span>
					<img src={ProfilePic} />
				</span>
			</a>
				{/* <a href="#">
						<i className="ph-swap"></i>
						<span>Dashboard</span>
				</a> */}
				<a href="#" id="dashboardLink" onClick={() => {
                    enableGrade();
                }} >
					<i className="ph-browsers"></i>
					<span>Grade History</span>
				</a>
				<a href="#" id="mockLink" onClick={() => {
                    enableMock();
                }} >
					<i className="ph-check-square"></i>
					<span>Mock History</span>
				</a>
				{/* <a href="#">
					<i className="ph-swap"></i>
					<span>Practice History</span>
				</a> */}
				{/* <a href="#">
					<i className="ph-globe"></i>
					<span>Achivements</span>
				</a> */}
				{isAdmin ? 
					(<Link to="/admin">
					<i className="ph-globe"></i>
					<span>Admin</span>
					</Link>)
				: null}
                {/* <a href="#">
					<i className="ph-file-text"></i>
					<span>Settings</span>
				</a> */}
			</nav>
		</div>
		<div className="app-body-main-content">
			<section className="service-section">
            {data && displayGrade ? <GradeChart data={data} /> : <p></p>}
			{displayMock ? <HistoryCards></HistoryCards> : <p></p>}	
			</section>
	    </div>
		{/* <div className="app-body-sidebar">
			<section className="">
				<h4>Practice Calendar</h4>
				<div className="">
					<p>Track your progress this month</p>
				</div>
			</section>
            <section>
            <div className="calendar-grid">
                {days.map((day, index) => (
                    <div key={index} className={`day level-${day}`} />
                ))}
            </div>
            </section>
		</div> */}
	</div>
    <Footer></Footer>
</div>
    );
};

export default DashboardPage;

import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { API_URL } from '../../Constants/constants';
import axios from 'axios';
import { refreshToken } from '../Auth/Authentication';

function Card(prop) {
    const {dialogue_id} = prop;
    const [topic_id, setTopic_ID] = useState(null);
    const [content, setContent] = useState(null);
    const [done, setDone] = useState(false);
    const [score, setScore] = useState(null);

    const starArray = Array.from({ length: score }, (_, index) => index);

    const fetchData = async () => {
        try {
          const resultResponse = await axios.get(API_URL + '/mock/get_history/' + dialogue_id, {
            headers: {
              'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
            }
          });
    
          if (resultResponse.status === 200) {
            console.log(resultResponse.data);
            setTopic_ID(resultResponse.data.topic_id);
            setContent(resultResponse.data.dialogue[0]);
            setScore(Math.max(Math.min(Math.ceil(parseFloat(resultResponse.data.grader.score) / 0.8), 5), 1));
            setDone(true);
            return;
          } else {
            console.error('Error getting dialogue result:', resultResponse.statusText);
            return;
          }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (error.response.data.msg === 'Token has expired') {
                    try {
                        if (refreshToken()) {
                            fetchData();
                            return;
                        }
                    } catch (e) {
                        console.error('Error refreshing token:', e);
                    }
                }
            }
          console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="col-lg-4">
            <Link to={'/feedback/' + dialogue_id} className="card border-0 card-hover-translate shadow-tiny rounded-4 limited-size-card">
                <div className="card-body">
                    <ul className="list list-row gy-0 g-1 mb-3"  style={done ? null : {display: 'none'}}>
                        {starArray.map((index) => (
                            <li  key={index}><em className="icon text-warning ni ni-star-fill fs-5"></em></li>
                        ))}
                    </ul>
                    <h4 className="title mb-3">{done ? ('Topic ' +  topic_id) : null}</h4>
                    <p>{done ? (content.length > 100 + 3 ? content.slice(0, 100) + '...' : content) : null}</p>
                </div>
            </Link>
        </div>
    );
}

function HistoryCards() {
    require('../../assets/css/Card.css');
    const [history, setHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [fetched, setFetched] = useState(false);
    const [noHistory, setNoHistory] = useState(false);
    const navigate = useNavigate();

    const cardsPerPage = 9;
    const nowHistory = history.slice((page - 1) * cardsPerPage, page * cardsPerPage);

    // Group the history array into rows of three
    const rows = [];
    for (let i = 0; i < nowHistory.length; i += 3) {
      const row = nowHistory.slice(i, i + 3);
      rows.push(row);
    }

    const retrieveHistory = async () => {
        try {
            const response = await axios.get(API_URL + '/mock/get_all_dialogues', {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                }
            });
            setFetched(true);
            if (response.status === 200) {
                setHistory(response.data.dialogue_ids);
                console.log(response.data.dialogue_ids);
            } else if (response.status === 204) {
                setNoHistory(true);
            } else {
                throw Error(response);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (error.response.data.msg === 'Token has expired') {
                    try {
                        if (refreshToken()) {
                            retrieveHistory();
                            return;
                        }
                    } catch (e) {
                        console.error('Error refreshing token:', e);
                    }
                }
            }
          console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (!sessionStorage.getItem('access')) {
            navigate('/login');
        }
        retrieveHistory();
    }, []);

    return (
        <section className="section section-bottom-0 has-shape has-mask">
        <div className="nk-shape bg-shape-blur-m mt-n3 start-50 top-0 translate-middle-x"></div>
        {/* <div className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div> */}
        <div className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
            {noHistory ? 
            <h3 className='text-center'>No history</h3>
            :
            <div>
                <div style={fetched ? null : {display: 'none'}}>
                    <h3 className='text-center'>Page {page} out of {Math.ceil(history.length / cardsPerPage)}</h3>
                    <br />
                </div>
                <div className="container">
                    <div className="section-content">
                        {rows.map((row, index) => (
                            <div key={index}>
                                <div className="row g-gs justify-content-center align-items-center">
                                    {row.map((history) => (
                                        <Card key={history} dialogue_id={history}/>
                                    ))}
                                </div>
                                <br />
                            </div>
                        ))}
                    </div>
                    <div className="section-actions text-center" style={fetched ? null : {display: 'none'}}>
                        <ul className="btn-list btn-list-inline g-gs">
                            <li><button className="btn btn-primary btn-soft btn-lg" disabled={page <= 1} onClick={() => setPage(page - 1)}>
                                <em className="icon ni ni-arrow-long-left" />
                                <span>Previous</span>
                                </button>
                            </li>
                            <li><button className="btn btn-primary btn-lg" disabled={page >= history.length / cardsPerPage} onClick={() => setPage(page + 1)}>
                                <span>Next</span>
                                <em className="icon ni ni-arrow-long-right" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>}
        </section>
    );
}

export default HistoryCards;

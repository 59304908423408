import React from 'react';
import MockModePage from './MockModePage';

function UploadModePage() {
    return (
        <MockModePage uploadMode={true} />
    );
}

export default UploadModePage;

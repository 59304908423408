import React, { useRef, useEffect, useState} from 'react';
import { Line, Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function GradeChart({ data }) {

    // const chartData = {
    //     labels: ["grader1", "grader2", "grader3"],
    //     datasets: [
    //         {
    //           id: 1,
    //           label: 'Your grader trends',
    //           data: [90, 85, 70],
    //         },
    //     ],
    // };

    let i = data.graders.length; 
    let length = Array.from({ length: 10 }, (_, j) => j + 1);

    let coherence = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            coherence.push(0);
        }else{
            coherence.push(data.graders[n].coherence.score);
        }
    }

    let grammar = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            grammar.push(0);
        }else{
            grammar.push(data.graders[n].grammar.score);
        }
    }

    let logic = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            logic.push(0);
        }else{
            logic.push(data.graders[n].logic.score);
        }
    }

    let example = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            example.push(0);
        }else{
            example.push(data.graders[n].example.score);
        }
    }

    let word = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            word.push(0);
        }else{
            word.push(data.graders[n].word.score);
        }
    }

    let overall = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            overall.push(0);
        }else{
            overall.push(data.graders[n].composite_grade);
        }
    }

    let speech = [];
    for (let n = Math.max(i-10, 0); n < i; n++) {
        if (data.graders[n] == null){
            speech.push(0);
        }else{
            speech.push(data.graders[n].speech_grade);
        }
    }

    const Overall = {
        labels: length,
        datasets: [
            {
                label: 'Overall',
                data: overall,
            },
        ],
    };

    const Others = {
        labels: length,
        datasets: [
            {
                label: 'Coherence',
                data: coherence,
            },
            {
                label: 'Grammar',
                data: grammar,
            },
            {
                label: 'Logic',
                data: logic,
            },
            {
                label: 'Use of examples',
                data: example,
            },
            {
                label: 'Word Choice',
                data: word,
            },
            {
                label: 'Speech',
                data: speech,
            },
        ],
    };

    const overallOptions = {
        scales: {
            y: {
                max: 4
            }
        }
    }

    const otherOptions = {
        scales: {
            y: {
                max: 4
            }
        }
    }

    return (
    <>
    <div className="row justify-content-center text-center">
        <h4 className="title mb-3">Grades from your last 10 mocks</h4>
    </div>
    <div> 
        <Bar data={Others} options={otherOptions}/>
    </div>
    <div>    
        <Line data={Overall} options={overallOptions}/>
    </div>
    </>);
}

export default GradeChart;
import '../../assets/css/FeedbackPage.css';
import '../../assets/css/ChatBox.css';
import React, { useState, useEffect} from 'react';
import Footer from '../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../Constants/constants';
import axios from 'axios';
import { refreshToken } from '../Auth/Authentication';
import Header from '../Header';
import Conversation from './Conversation';

import grammar_logo from '../../assets/fbimg/grammar.svg';
import word_logo from '../../assets/fbimg/word.svg';
import example_logo from '../../assets/fbimg/example.svg';
import logic_logo from '../../assets/fbimg/logic.svg';
import coherence_logo from '../../assets/fbimg/coherence.svg';
import pronunciation_logo from '../../assets/fbimg/pronunciation.svg';

const DimensionColorMapping = {
    'coherence': "FF9E0D",
    'example': "FE7E7B",
    'grammar': "6C5BF3",
    'logic': "4C74FF",
    'word': "52BEA4",
    'pronunciation': "25C0DA"
}

const TotalScoreIcon = ({ dimension }) => {
    const bgColor = DimensionColorMapping[dimension];

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "5px"}}>
            <path d="M11.2078 19.064L6.94449 21.246C6.74527 21.3444 6.5484 21.3444 6.34918 21.246C6.14996 21.1476 6.05153 20.8499 6.14996 20.6507L6.94215 15.9913C7.04059 15.396 6.84371 14.8007 6.44527 14.4046L3.07496 11.0343C2.87574 10.8351 2.87574 10.6382 2.97653 10.439C3.07731 10.2397 3.27418 10.0429 3.4734 10.0429L8.23121 9.34912C8.82653 9.25068 9.32106 8.95303 9.52028 8.35772L11.6015 4.09443C11.7 3.89521 11.8992 3.79678 12.0984 3.79678C12.2976 3.79678 12.4945 3.89521 12.5953 4.09443L14.5804 8.45615C14.8781 8.95303 15.3726 9.34912 15.9679 9.44756L20.6273 10.1413C20.8265 10.1413 21.0234 10.3405 21.1242 10.5374C21.2226 10.7366 21.1242 10.9335 21.0257 11.1327L17.6531 14.503C17.257 14.8991 17.0578 15.4944 17.1562 16.0897L17.9484 20.7491C17.9484 20.9483 17.85 21.1452 17.7492 21.3444C17.6507 21.5437 17.3531 21.4429 17.1539 21.3444L12.8906 19.1624C12.3 18.7663 11.7047 18.7663 11.2078 19.064ZM16.65 22.746C17.2711 23.0554 17.9929 23.0554 18.614 22.6429C19.1296 22.2304 19.4414 21.5062 19.3382 20.8851L18.5109 16.0288C18.5109 15.8226 18.5109 15.6163 18.7172 15.5132L22.2304 11.8944C22.6429 11.3788 22.8515 10.6546 22.6429 10.0335C22.4343 9.4124 21.8156 8.89678 21.1968 8.79365L16.2375 8.06943C16.0312 8.06943 15.825 7.96631 15.825 7.76006L13.6547 3.31631C13.3453 2.69521 12.7242 2.28271 12.0023 2.28271C11.2804 2.28271 10.6593 2.69521 10.35 3.31631L8.17965 7.76006C8.07652 7.96631 7.87028 8.06943 7.76715 8.06943L2.80778 8.79365C2.08356 8.89678 1.56793 9.41475 1.36168 10.0335C1.15543 10.6546 1.36168 11.3765 1.77418 11.8944L5.28278 15.4077C5.3859 15.5108 5.48903 15.7171 5.48903 15.9233L4.66403 20.7819C4.5609 21.5062 4.87028 22.1249 5.38824 22.5397C5.90387 22.9522 6.73121 22.9522 7.35231 22.6429L11.7961 20.3694C12.0023 20.2663 12.2086 20.2663 12.3117 20.3694L16.65 22.746Z" fill="#4F4F4F"/>
            <path d="M9.53201 17.1161H14.4984C14.9508 17.1161 15.4008 16.7435 15.4008 16.3708C15.4008 15.9981 14.9484 15.6255 14.4984 15.6255H9.53201C9.07966 15.6255 8.62966 15.9981 8.62966 16.3708C8.62966 16.7435 9.08201 17.1161 9.53201 17.1161Z" fill={"#" + bgColor}/>
        </svg>
    );
};

const DimensionButton = ({ name, selected, dimensionName, setDimension }) => {
    var svg;
    switch (dimensionName) {
        case 'coherence':
            svg = coherence_logo;
            break;
        case 'example':
            svg = example_logo;
            break;
        case 'grammar':
            svg = grammar_logo;
            break;
        case 'logic':
            svg = logic_logo;
            break;
        case 'word':
            svg = word_logo;
            break;
        case 'pronunciation':
            svg = pronunciation_logo;
            break;
        default:
            break;
    }

    return (
        <button className={"dimension-selector " + (selected ? "dimension-" + dimensionName : "")} onClick={() => setDimension(dimensionName)}>
            <span className="dimension-icon">
                <img src={svg} className={(selected ? "selected" : "")}/>
            </span>
            {name}
        </button>
    );
};

const Explanation = ({ grader, dimension }) => {
    return (
        <div className="explanation-box">
            <div className="title" style={{color: "#" + DimensionColorMapping[dimension]}}>
                Explanation：
            </div>
            <div className="content">
                {grader.grader[dimension].explanation}
            </div>
        </div>
    );
};

const CoherenceEvaluation = ({ grader }) => {
    if (!grader.grader.coherence.score) {
        return <div />;
    }

    const dimension = 'coherence';

    const structure_outline = grader.grader[dimension].structure_outline.split('\n');

    return (
        <div>
            <div className="active-details">
                <div className={"circle " + "shallow-dimension-" + dimension} style={{width: "24px", height: "24px"}}>
                    <div className="details-number" style={{color: "#" + DimensionColorMapping[dimension]}}>
                        {1}
                    </div>
                </div>
                <div className="details-points">
                    <div className="details-point details-main-point">{'Structure Outline:'}</div>
                    {structure_outline.map((e, i) => <div key={i} className="details-point">{e}</div>)}
                </div>
                <br />
            </div>
            <Explanation grader={grader} dimension={dimension}/>
        </div>
    );
};

function FeedbackPage () {
    require('../../assets/css/style-s3.css');
    require('../../assets/css/FeedbackPage.css');
    const dialogue_id = useParams().dialogueID;
    const [topic_id, setTopic_ID] = useState(-1);
    const [messages, setMessages] = useState(null);
    const [mockAudioBlobUrl, setMockAudioBlobUrl] = useState(null);
    const [dimension, setDimension] = useState('pronunciation');
    const [dimensionError, setDimensionError] = useState(false);
    const [feedbackText, setFeedbackText] = useState('N/A');
    const [topicText, setTopicText] = useState('N/A');
    const [grader, setGrader] = useState(null);
    const [audioID, setAudioID] = useState(null);
    const navigate = useNavigate();

    /* Mock Audio. */
    const retrieveMockAudio = async () => {
        if (!audioID) {
            return;
        }
        try {
            const response = await axios.get(API_URL + '/mock/get_audio/' + audioID, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                }
            });
            if (response.status === 200) {
                console.log(response);
                attachMockAudio(response.data.audio);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (error.response.data.msg === 'Token has expired') {
                    try {
                        if (refreshToken()) {
                            retrieveMockAudio();
                            return;
                        }
                    } catch (e) {
                        console.error('Error refreshing token:', e);
                    }
                }
            }
          console.error('Error:', error);
        }
    };

    const attachMockAudio = (audioBase64) => {
        const audioBlob = base64ToBlob(audioBase64, "audio/wav");
        console.log(audioBlob);
        setMockAudioBlobUrl(URL.createObjectURL(audioBlob));
    }

    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

    // Pronunciation Eval
    const [activeWord, setActiveWord] = useState(null);

    const getColor = score => {
        if (score >= 90) return 'green';
        if (score >= 70) return 'blue';
        if (score >= 50) return 'orange';
        return 'red';
    };

    const findSentence = (sentence) => {
        var ele = null;
        switch (dimension) {
            case 'coherence':
                break;
            case 'example':
                for (const index in grader.grader.example.examples) {
                    if (grader.grader.example.examples[index].indexOf(sentence.sentence) >= 0 || sentence.sentence.indexOf(grader.grader.example.examples[index]) >= 0) {
                        if (!ele) {
                            ele = {sentence: sentence.sentence,
                                    mistakes: [{
                                        sentence: grader.grader.example.examples[index],
                                        recommendation: grader.grader.example.recommendations[index],
                                        suggestion: grader.grader.example.suggestions[index]
                                    }]};
                        } else {
                            ele.mistakes.push({
                                sentence: grader.grader.example.examples[index],
                                recommendation: grader.grader.example.recommendations[index],
                                suggestion: grader.grader.example.suggestions[index]
                            });
                        }
                    }
                }
                break;
            case 'grammar':
                for (const element of grader.grader.grammar.errors) {
                    if (element.sentence.indexOf(sentence.sentence) >= 0 || sentence.sentence.indexOf(element.sentence) >= 0) {
                        if (!ele) {
                            ele = {sentence: sentence.sentence,
                                    mistakes: [{
                                        sentence: element.sentence,
                                        fixed_sentence: element.fixed_sentence,
                                        error_type: element.error_type
                                    }]};
                        } else {
                            ele.mistakes.push({
                                sentence: element.sentence,
                                fixed_sentence: element.fixed_sentence,
                                error_type: element.error_type
                            });
                        }
                    }
                }
                break;
            case 'logic':
                for (const element of grader.grader.logic.logical_flaws) {
                    if (element.sentence.indexOf(sentence.sentence) >= 0 || sentence.sentence.indexOf(element.sentence) >= 0) {
                        if (!ele) {
                            ele = {sentence: sentence.sentence,
                                    mistakes: [{
                                        sentence: element.sentence,
                                        flaw_category: element.flaw_category,
                                        flaw_description: element.flaw_description
                                    }]};
                        } else {
                            ele.mistakes.push({
                                sentence: element.sentence,
                                flaw_category: element.flaw_category,
                                flaw_description: element.flaw_description
                            });
                        }
                    }
                }
                break;
            case 'word':
                for (const element of grader.grader.word.issues) {
                    if (element.sentence.indexOf(sentence.sentence) >= 0 || sentence.sentence.indexOf(element.sentence) >= 0) {
                        if (!ele) {
                            ele = {sentence: sentence.sentence,
                                    mistakes: [{
                                        sentence: element.sentence,
                                        issue_type: element.issue_type,
                                        issue_word: element.issue_word,
                                        suggested_alternative: element.suggested_alternative
                                    }]};
                        } else {
                            ele.mistakes.push({
                                sentence: element.sentence,
                                issue_type: element.issue_type,
                                issue_word: element.issue_word,
                                suggested_alternative: element.suggested_alternative
                            });
                        }
                    }
                }
                break;
            case 'pronunciation':
            default:
                break;
        }
        if (ele) {
            for (const element in ele) {
                sentence[element] = ele[element];
            }
            return true;
        }
        return false;
    }

    const renderWord = (word, i) => {
        const color = dimension === 'pronunciation' ? getColor(word.pronunciation)
        : findSentence(word) ? 'orange' : 'black';

        const shouldBoldOnHover = findSentence(word)|| dimension === 'pronunciation';

        return (
            <span
            key={i}
            className={shouldBoldOnHover ? "fb-hover-bold" : ""}
            style={{ color }}
            onMouseEnter={() => dimension === 'coherence' ? undefined : setActiveWord(word)}
            onMouseLeave={() => dimension === 'coherence' ? undefined : setActiveWord(null)}
        >
            {dimension === 'pronunciation' ? word.word + ' ' : word.sentence + '. '}
        </span>
        );
    };

    const renderPhoneme = (phoneme, i) => {
        let advice = "";
        let phonemeSymbol = "";
    
        // Checking if phoneme.phoneme is a unicode escape sequence
        if (phoneme.phoneme.includes("\\x")) {
            // Splitting the string on '\x' and converting the hexadecimal values to decimal, then to characters
            phonemeSymbol = phoneme.phoneme.split("\\x").slice(1).map(hex => String.fromCharCode(parseInt(hex, 16))).join("");
        } else {
            phonemeSymbol = phoneme.phoneme;
        }
    
        if (phoneme.pronunciation > 95) {
            advice = `Your pronunciation of the phoneme "${phonemeSymbol}" is great. Keep it up!`;
        } else {
            advice = `Your pronunciation of the phoneme "${phonemeSymbol}" needs improvement. Your pronunciation score for this phoneme is ${phoneme.pronunciation}.`;
            
            if ((phoneme.stress_ref && phoneme.prominence <= 70) || (!phoneme.stress_ref && phoneme.prominence >= 70)) {
                if (phoneme.stress_ref) {
                    advice += ` You need to stress this phoneme more.`;
                } else {
                    advice += ` You are stressing this phoneme too much.`;
                }
            }
        }
    
        return (
            <li key={i}>
                {advice}
            </li>
        );
    };

    const renderElement = (element) => {
        if (dimensionError) {
            return;
        }
        switch (dimension) {
            case 'coherence':
                break;
            case 'example':
                return (
                    <div>
                        {element.mistakes && element.mistakes.map((e, i) => 
                            <div key={i} className="active-details">
                                <div className={"circle " + "shallow-dimension-" + dimension} style={{width: "24px", height: "24px"}}>
                                    <div className="details-number" style={{color: "#" + DimensionColorMapping[dimension]}}>
                                        {i + 1}
                                    </div>
                                </div>
                                <div className="details-points">
                                    <div className="details-point details-main-point">{'Examples:'}</div>
                                    <div className="details-point">{'Recommendation: ' + e.recommendation}</div>
                                    <div className="details-point">{'Suggestion: ' + e.suggestion}</div>
                                </div>
                                <br />
                            </div>)}
                    </div>
                );
            case 'grammar':
                return (
                    <div>
                        {element.mistakes && element.mistakes.map((e, i) => 
                            <div key={i} className="active-details">
                                <div className={"circle " + "shallow-dimension-" + dimension} style={{width: "24px", height: "24px"}}>
                                    <div className="details-number" style={{color: "#" + DimensionColorMapping[dimension]}}>
                                        {i + 1}
                                    </div>
                                </div>
                                <div className="details-points">
                                    <div className="details-point details-main-point">{'Error Type: ' + e.error_type}</div>
                                    <div className="details-point">{'Fixed Sentence: ' + e.fixed_sentence}</div>
                                </div>
                                <br />
                            </div>)}
                    </div>
                );
            case 'logic':
                return (
                    <div>
                        {element.mistakes && element.mistakes.map((e, i) => 
                            <div key={i} className="active-details">
                                <div className={"circle " + "shallow-dimension-" + dimension} style={{width: "24px", height: "24px"}}>
                                    <div className="details-number" style={{color: "#" + DimensionColorMapping[dimension]}}>
                                        {i + 1}
                                    </div>
                                </div>
                                <div className="details-points">
                                    <div className="details-point details-main-point">{'Flaw Category: ' + e.flaw_category}</div>
                                    <div className="details-point">{'Flaw Description: ' + e.flaw_description}</div>
                                </div>
                                <br />
                            </div>)}
                    </div>
                );
            case 'word':
                return (
                    <div>
                        {element.mistakes && element.mistakes.map((e, i) => 
                            <div key={i} className="active-details">
                                <div className={"circle " + "shallow-dimension-" + dimension} style={{width: "24px", height: "24px"}}>
                                    <div className="details-number" style={{color: "#" + DimensionColorMapping[dimension]}}>
                                        {i + 1}
                                    </div>
                                </div>
                                <div className="details-points">
                                    <div className="details-point details-main-point">{'Issue Type: ' + e.issue_type}</div>
                                    <div className="details-point">{'Issue Word: ' + e.issue_word}</div>
                                    <div className="details-point">{'Suggested Alternative: ' + e.suggested_alternative}</div>
                                </div>
                                <br />
                            </div>)}
                    </div>
                );
            case 'pronunciation':
                return element.phonemes.map(renderPhoneme);
            default:
                break;
        }
    };

    useEffect(() => {
        /* NON-PRONUNCIATION */
        if (grader && dimension !== 'pronunciation') {
            if (!grader.grader[dimension].score) {
                setDimensionError(true);
                setFeedbackText(
                    <span style={{ color: 'red' }}>
                        Error
                    </span>
                );
                return;
            }
            const text = grader.dialogue[0];
            const text_array = text.split(/[!.?]+/).filter((str) => str.length > 0).map((str) => str.trimStart());
            const result = text_array.map(str => ({ sentence: str }));
            setDimensionError(false);
            setFeedbackText(result.map(renderWord));
        }
    }, [grader, dimension]);

    useEffect(() => {
        if (!sessionStorage.getItem('access')) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        retrieveMockAudio();
    }, [audioID]);

    useEffect(() => {
        /* PRONUNCIATION */
        if (grader && dimension === 'pronunciation') {
            setFeedbackText(grader.grader.speech_feedback.words.map(renderWord));
        }
    }, [dimension, grader]);

    useEffect (() => {
        const fetchData = async () => {
            console.log("fetchData");
            try {
              const resultResponse = await axios.get(API_URL + '/mock/get_history/' + dialogue_id, {
                headers: {
                  'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                }
              });
        
              if (resultResponse.status === 200) {
                let topic_id = resultResponse.data.topic_id;
                console.log(resultResponse.data);
                setGrader(resultResponse.data);
                setTopic_ID(topic_id);
                setAudioID(resultResponse.data.audio_id);
                setMessages(resultResponse.data.dialogue);
                /* Be careful! If data is empty (cannot be parsed), this throws an error.
                    Anything after that would not be executed. */
                await getTopic(topic_id);
                return;
              } else {
                console.error('Error getting dialogue result:', resultResponse.statusText);
                return;
              }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    if (error.response.data.msg === 'Token has expired') {
                        try {
                            if (refreshToken()) {
                                fetchData();
                                return;
                            }
                        } catch (e) {
                            console.error('Error refreshing token:', e);
                        }
                    }
                }
              console.error('Error:', error);
            }
          };

    const getTopic = async (topic_id) => {
            console.log("getTopic");
            try {
              const resultResponse = await axios.get(API_URL + '/topic/all');
        
              if (resultResponse.status === 200) {
                console.log(resultResponse.data[topic_id]);
                setTopicText(resultResponse.data[topic_id].text);
                /* Be careful! If data is empty (cannot be parsed), this throws an error.
                    Anything after that would not be executed. */
                return;
              } else {
                console.error('Error getting dialogue result:', resultResponse.statusText);
                return;
              }
            } catch (error) {
              console.error('Error:', error);
            }
        };
        
        fetchData();

    }, [dialogue_id]);

    return (
        <div className="nk-app-root ">
        <main className="nk-pages">
            <Header></Header>
            <section className="section has-shape has-mask">
                <div className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                <div className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div className="container">
                    <div className="section-head">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-9 col-xl-8 col-xxl-7">
                                <h2 className="title h1">Your Speaking Report</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row g-gs justify-content-center align-items-center flex-lg-row-reverse">
                            <div className="col-lg-6 col-md-11">
                                <div className="block-gfx ps-xxl-5">
                                    <img className="w-100" src="images/gfx/about.png" alt=""/>
                                </div>
                            </div>
                            <div className='feedback-container'>
                                <div className="feedback-left">
                                <div className="text-box">
                                    <div className='topic-boxline'></div>
                                        <div className="topic-title">
                                            Topic&nbsp; {topic_id}
                                        </div>
                                        <div className="topic-content">
                                            {topicText}
                                        </div>
                                </div>
                                    <div>
                                        {mockAudioBlobUrl &&
                                        <audio id="mock-audio" controls className="mock-audio" src={mockAudioBlobUrl} style={{width: "100%"}} />}
                                    </div>
                                    <div className="text-box">
                                        <div className="title">
                                            Text
                                            <span className="total-score">
                                                <TotalScoreIcon dimension={"coherence"} />
                                                Overall Score
                                                <span className="total-score-points"  style={{color: "#" + DimensionColorMapping['coherence']}}>{(grader &&  Math.round((grader.grader.composite_grade + Number.EPSILON) * 100) / 100) + " / 4.0"}</span>
                                            </span>
                                        </div>
                                        <div className="content">
                                            {feedbackText}
                                        </div>
                                    </div>
                                    <div className="dimension-selector-container">
                                        <DimensionButton name="Grammar" selected={dimension === 'grammar'} dimensionName={'grammar'} setDimension={setDimension}/>
                                        <DimensionButton name="Word Choice" selected={dimension === 'word'} dimensionName={'word'} setDimension={setDimension}/>
                                        <DimensionButton name="Use of Examples" selected={dimension === 'example'} dimensionName={'example'} setDimension={setDimension}/>
                                        <DimensionButton name="Logic" selected={dimension === 'logic'} dimensionName={'logic'} setDimension={setDimension}/>
                                        <DimensionButton name="Coherence" selected={dimension === 'coherence'} dimensionName={'coherence'} setDimension={setDimension}/>
                                        <DimensionButton name="Pronunciation" selected={dimension === 'pronunciation'} dimensionName={'pronunciation'} setDimension={setDimension}/>
                                    </div>
                                    <div className={"themed-grid-col " + "shallow-dimension-" + dimension}>
                                        <div className="left-items">
                                            <span>
                                                <div className={"circle " + "dimension-" + dimension}>
                                                    <div className="tick"></div>
                                                </div>
                                            </span>
                                            <div>
                                                {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
                                            </div>
                                        </div>
                                        <span className="total-score">
                                            <TotalScoreIcon dimension={dimension} />
                                            Score
                                            <span className="total-score-points" style={{color: "#" + DimensionColorMapping[dimension]}}>{grader && (dimension === 'pronunciation' ? grader.grader.speech_grade : (grader.grader[dimension].score || 'Error'))}</span>
                                        </span>
                                    </div>
                                    <ul className="list gy-3 pe-xxl-7">
                                    <div className="col-lg-12" id="card">
                                            {dimension === 'coherence' ? <CoherenceEvaluation grader={grader}/>
                                            : activeWord ? (
                                                <>
                                                    {dimension === 'pronunciation' && (
                                                        <h5>Details for "{activeWord.word}" (Pronunciation score: {activeWord.pronunciation})</h5>
                                                    )}
                                                    
                                                    <div>{renderElement(activeWord)}</div>
                                                </>
                                            ):(
                                                    dimension !== 'pronunciation' && grader && <Explanation grader={grader} dimension={dimension}/> 
                                            )}
                                    </div>
                                    </ul>
                                </div>
                                <div className="feedback-right">
                                    {topic_id >= 0 && messages &&
                                        <Conversation topic_id={topic_id} dialogue_id={dialogue_id} arg_messages={messages}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer></Footer>
    </div>
    );
};

export default FeedbackPage;

import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../Constants/constants';
import { refreshToken } from './Authentication';

export const handleLogout = () => {
  // Perform logout logic here
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
};

const User = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('User');

  useEffect(() => {
    if (localStorage.getItem('access')) {
      sessionStorage.setItem('access', localStorage.getItem('access'))
      sessionStorage.setItem('refresh', localStorage.getItem('refresh'))
    }
    attemptMeAndRefresh();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setUsername(sessionStorage.getItem('username'));
    }
  }, [isLoggedIn]);

  const attemptMeAndRefresh = async () => {
    if (sessionStorage.getItem('access')) {
      try {
        const responses = await axios.get(API_URL + '/auth/me', {
          headers: {
              'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
          }
        });
        if (responses.status === 200) {
          sessionStorage.setItem('username', responses.data.username);
          sessionStorage.setItem('email', responses.data.email);
          setIsLoggedIn(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('User refreshing');
          if (error.response.data.msg === 'Token has expired') {
              try {
                  if (!refreshToken()) {
                      throw 'Unable to refresh token';
                  } else {
                    attemptMeAndRefresh();
                    return;
                  }
              } catch (e) {
                  console.error('Error refreshing token:', e);
              }
          } else {
              console.error('Unknown error:', error.response.statusText);
          }
        }
        console.error('Unknown error:', error);
        handleLogout();
      }
    }
  }

  return (
    <div>
      {isLoggedIn ? (
        <div>
        <ul className="nk-menu-buttons flex-lg-row-reverse">
          <li><Link to ="/mock" className="nk-menu-link"><span onClick = {handleLogout}>Logout</span></Link></li>
          <li><span className="nk-menu-text">Welcome, <Link to ="/dashboard">{username}</Link></span></li>
        </ul>
        </div>
      ) : (sessionStorage.getItem('access') ?
      null
      : (
        <div>
          <Link to='/login' className="link link-dark">Login </Link>
        </div>
      ))}
    </div>
  );
};

export default User;

import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Cards from './Cards'

function MockIndex() {
    require('../../assets/css/style-s3.css');
    return (
    <div>
    <div className="nk-app-root ">
        <Header></Header>
        <main className="nk-pages">
            <Cards></Cards>
        </main>
        <Footer></Footer>
    </div>
    </div>
    );
}

export default MockIndex;

import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import { API_URL } from '../../Constants/constants';
import axios from 'axios';

function CardContent(prop) {
    const {title, description, icon, text_bg} = prop;

    return (
        <div className="feature">
            <div className="feature-media">
                <div className={"media media-middle media-xl " + (text_bg ? text_bg : "text-bg-success-soft") + " rounded-pill"}>
                    <em className={ "icon ni " + (icon ? icon : "ni-globe")}></em>
                </div>
            </div>
            <div className="feature-text text-base">
                <h3 className="title">{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
}

export function Card(prop) {
    const {title, description, index, icon, text_bg, normal} = prop;
    const [isHovered, setIsHovered] = useState(false);
    const [onButton, setOnButton] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="col-md-6 col-xl-4">
            <div className="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 no-transform">
                {normal ?
                <div className="card-body">
                    <CardContent title={title} description={description} icon={icon} text_bg={text_bg}/>
                </div>
                :
                <div className={`card-body blurred-component ${(isHovered || onButton) ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                    <CardContent title={title} description={description} icon={icon} text_bg={text_bg}/>
                </div>}
                {(isHovered || onButton) && (
                    <div className="card-buttons"
                    onMouseEnter={() => setOnButton(true)}
                    onMouseLeave={() => setOnButton(false)}>
                    <button className="btn btn-primary" onClick={() => navigate(`/mock/${index}`)}>Mock</button>
                    <button className="btn btn-secondary" onClick={() => navigate(`/mock/upload/${index}`)}>Upload Audio</button>
                    </div>
                )}
            </div>
        </div>
    );
}

function Cards() {
    require('../../assets/css/Card.css');
    const [topics, setTopics] = useState([]);
    const [unfinished, setUnifinished] = useState([]);
    
    const fetchTopics = async () => {
        const response = await axios.get(API_URL + '/topic/all');
        setTopics(response.data);
    };

    const fetchUnfinished = async () => {
        const response = await axios.post(API_URL + '/topic/random_unfinished', {
            number: 3,
          }, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
            }
        });
        console.log(response.status);
        console.log(response);
        setUnifinished(response.data.questions);
        console.log(Array.isArray(unfinished));
    };

    useEffect(() => {
        fetchTopics();
        fetchUnfinished();
    }, []);

    return (
        <section className="section section-bottom-0 has-shape has-mask">
        <div className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
        <div className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
            <div className="container">
            <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-8">
                            {/* <h6 className="overline-title text-primary">Section 2</h6> */}
                            <h1 className="title"><span className="text-primary">SmartPrep</span> TOEFL Mock</h1>
                            <h6 className="overline-title text-primary">Your Personalized Topic feed</h6>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row text-center g-gs">
                    {unfinished.map(topic => 
                        <Card key={topic} title={"Topic " + topic} index={topic}/>)}
                    </div>
                </div>

                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-8">
                            {/* <h6 className="overline-title text-primary">Section 1</h6> */}
                            <h6 className="overline-title text-primary">All Topics</h6>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row text-center g-gs">
                        {topics.map(topic => 
                        <Card key={topic.id} title={"Topic " + topic.id} index={topic.id}/>)}
                    </div>
                </div>
                
            </div>
        </section>
    );
}

export default Cards;

import React, { useState } from 'react';
import AudioRecorder from './AudioRecorder';

function RecordTest({ setStatus }){
    const [canStart, setCanStart] = useState(false);
    return(
        <div className="my-auto">
            <div className="container">
                <div className="row g-gs justify-content-center">
                    <div className="col-md-7 col-lg-6 col-xl-5">
                        <div className="card border-0 shadow-sm rounded-4">
                            <div className="card-body">
                                <h4>Record test</h4>
                                {sessionStorage.getItem('isDemo') == 'true'? <h4>麦克风测试</h4>:<></>}
                                <p></p>
                                <p>Please test your microphone before proceeding.</p>
                                {sessionStorage.getItem('isDemo') == 'true'? <p>点击'Start Recording'，进行录音测试，然后点击'Play Audio'进行录音回放。测试完成后，点击'Start'即可进入模考页面。</p>:<></>}
                                <AudioRecorder setCanStart={setCanStart}/>
                            </div>
                        </div>
                        <p className="mt-4" style={{textAlign: "center"}}>
                            <button className="btn btn-primary" id="start-btn" onClick={() => {
                                setStatus('Recorded');
                            }} disabled={!canStart}>Start</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecordTest;

import React from 'react';
import Footer from '../Footer';

function MockFooter(){
    return(
        <Footer fix={true} dark={true}></Footer>
    );
}
        
export default MockFooter;

import React,{ useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { API_URL } from '../Constants/constants';
import { refreshToken } from './Auth/Authentication';
import Header from './Header';

const UserFeedback = ({}) => {
    require('../assets/css/style-s3.css');
    require('../assets/css/UserFeedback.css');

    const [contact, setContact] = useState('');
    const [feedback, setFeedback] = useState('');
    const [rating, setRating] = useState(0);

    const submitFeedback = async (e) => {
        e.preventDefault();
        if (!sessionStorage.getItem('access')) {
            alert('Please log in');
            return;
        }

        const formData = new FormData();
        formData.append('satisfaction', rating);
        formData.append('contact', contact);
        formData.append('feedback', feedback);

      try {
        const response = await axios.post(API_URL + '/mock/get_feedback', formData, {
            headers: {
              'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
              'Content-Type': 'multipart/form-data',
            },
        });    

        if (response.status === 200) {
            console.log(response.data);
            alert("提交成功！")
        } else {
          alert("Invalid credentials");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
            if (error.response.data.msg === 'Token has expired') {
                try {
                    if (refreshToken()) {
                        submitFeedback();
                        return;
                    }
                } catch (e) {
                    console.error('Error refreshing token:', e);
                }
            }
        }
        alert(error.response.data.error);
      }
    }

    function Star({ filled, onClick }) {
        return (
          <span
            className={`star ${filled ? "filled" : ""}`}
            onClick={onClick}
          >
            ★
          </span>
        );
      }
  
    return (
        <div className="nk-app-root ">
        <main className="nk-pages"/>
        <Header></Header>
            <div className="min-vh-100 d-flex flex-column has-mask">
                <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                <div className="my-auto">
                    <div className="container">
                        <div className="row g-gs justify-content-center">
                            <div className="col-md-7 col-lg-6 col-xl-5">
                                <div className="card border-0 shadow-sm rounded-4">
                                    <div className="card-body">
                                        <h4 className="mb-3">Feedback Form</h4>
                                        <form
                                            noValidate>
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fullname">Rating 评分</label>
                                                        {/* <div className="form-control-wrap">
                                                            <input name="fullname" id="fullname" className="form-control form-control-lg" placeholder="Your Contact" onInput={e => setRating(e.target.value)}/>
                                                        </div> */}
                                                        <div className="star-rating">
                                                            {[4,3,2,1,0].map((index) => {
                                                            const ratingValue = index + 1;
                                                            return (
                                                                <Star
                                                                key={ratingValue}
                                                                filled={ratingValue <= (rating)}
                                                                onClick={() => setRating(ratingValue)}
                                                                />
                                                            );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fullname">Contact 联系方式</label>
                                                        <div className="form-control-wrap">
                                                            <input name="fullname" id="fullname" className="form-control form-control-lg" placeholder="Your Contact" onInput={e => setContact(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="emailaddress">Feedback Details请简单描述您的问题或建议</label>
                                                        <div className="form-control-wrap">
                                                            <textarea name="emailaddress" id="emailaddress" className="feedbackwindow form-control form-control-lg" placeholder="Your Feedback" onInput={e => setFeedback(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                    <button to="/login" className="btn btn-primary btn-block" id="submit-btn" onClick={(e) => {
                                            submitFeedback(e);
                                            }} >Submit 提交</button> 
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    );
  };
  
  export default UserFeedback;
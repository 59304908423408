import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Registration from './Registration';
import axios from 'axios';
import { API_URL } from '../../Constants/constants';

export const refreshToken = async () => {
    console.log('Refreshing');
    if (!sessionStorage.getItem('refresh')) {
        throw 'No refresh token';
    }
    try {
        const response = await axios.get(API_URL + '/auth/token/refresh', {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('refresh'),
            }
        });
        if (response.status === 200) {
            sessionStorage.setItem('access', response.data.access);
            if (localStorage.getItem('access')) {
              localStorage.setItem('access', response.data.access);
            }
            return true;
        }
    } catch (error) {
        console.error('Error refreshing token', error.response.statusText);
        return false;
    }
};

const Authentication = () => {
  require('../../assets/css/style-s3.css');
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const handleLogin = async (e, login, password, rememberMe) => {
    if (e) e.preventDefault();
    try {
      const response = await axios.post(API_URL + '/auth/login', {
        login: login,
        password: password,
      });

      if (response.status === 200) {
        sessionStorage.setItem('access', response.data.user.access);
        sessionStorage.setItem('refresh', response.data.user.refresh);
        sessionStorage.setItem('username', response.data.user.username);
        sessionStorage.setItem('email', response.data.user.email);
        if (rememberMe) {
          localStorage.setItem('access', response.data.user.access);
          localStorage.setItem('refresh', response.data.user.refresh);
        }
        navigate('/mock');
      } else {
        alert("Invalid credentials");
      }
    } catch (err) {
      var err_msg = 'An error has occurred. Please try again later.\nIf this issue persists, please contact us with the following message.\n';
      if (err.response) {
        if (err.response.data && err.response.data.error) {
          err_msg = err.response.data.error;
        } else {
          err_msg += JSON.stringify(err.response);
        }
      } else {
        err_msg += JSON.stringify(err.message);
      }
      console.error(err_msg);
      console.error(err);
      alert(err_msg);
    }
  }

  return (
    path === '/login' ? <Login handleLogin={handleLogin}></Login> : <Registration handleLogin={handleLogin}></Registration>
  );
};

export default Authentication;

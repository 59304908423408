import React,{ useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { API_URL } from '../../Constants/constants';

const Registration = ({ handleLogin }) => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [invite_code, setInviteCode] = useState('');
    const [checkbox, setCheckbox] = useState(false);

    const createAccount = async (e) => {
        e.preventDefault();

    if (!checkbox) {
        alert("Please agree to our privacy policy and terms");
        return;
    }

      try {
        const response = await axios.post(API_URL + '/auth/register', (
            invite_code.length > 0 ?
            {
                username,
                email,
                password,
                phone,
                invite_code,
            } :
            {
                username,
                email,
                password,
                phone,
        }));
  
        console.log(response);
  
        console.log(response.status);
        console.log(phone);

        if (response.status === 201) {
            console.log(response.data);
            handleLogin(e, email, password);
        } else {
          alert("Invalid credentials");
        }
      } catch (err) {
        alert(err.response.data.error);
      }
    }
  
    return (
        <div className="nk-app-root ">
        <main className="nk-pages"/>
            <div className="min-vh-100 d-flex flex-column has-mask">
                <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                {/* <div className="text-center mt-6 mb-4">
                    <a href="index.html" className="logo-link">
                        <div className="logo-wrap">
                            <img className="logo-img logo-light" src="images/logo.png" srcSet="images/logo2x.png 2x" alt=""/>
                            <img className="logo-img logo-dark" src="images/logo-dark.png" srcSet="images/logo-dark2x.png 2x" alt=""/>
                        </div>
                    </a>
                </div> */}
                <div className="my-auto">
                    <div className="container">
                        <div className="row g-gs justify-content-center">
                            <div className="col-md-7 col-lg-6 col-xl-5">
                                <div className="card border-0 shadow-sm rounded-4">
                                    <div className="card-body">
                                        <h4 className="mb-3">Create an account</h4>
                                        <form
                                            noValidate>
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="fullname">Full Name</label>
                                                        <div className="form-control-wrap">
                                                            <input name="fullname" id="fullname" className="form-control form-control-lg" placeholder="Your Name" onInput={e => setUsername(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="emailaddress">Email Address</label>
                                                        <div className="form-control-wrap">
                                                            <input name="emailaddress" id="emailaddress" className="form-control form-control-lg" placeholder="name@copygen.com" onInput={e => setEmail(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="phone">Phone Number</label>
                                                        <div className="form-control-wrap">
                                                            <input name="emailaddress" id="phone" className="form-control form-control-lg" placeholder="Your Number" onInput={e => setPhone(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="first-password">Password</label>
                                                        <div className="form-control-wrap">
                                                            <input type="password" name="password" id="first-password" className="form-control form-control-lg" placeholder="Enter Password" onInput={e => setPassword(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="first-password">Invite Code</label>
                                                        <div className="form-control-wrap">
                                                            <input type="text" name="invitecode" className="form-control form-control-lg" placeholder="Optional" onInput={e => setInviteCode(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <div className="form-check form-check-sm">
                                                            <input className="form-check-input" type="checkbox" value="" id="iAgree" onChange={e => setCheckbox(e.target.checked)}/>
                                                            <label className="form-check-label small" htmlFor="iAgree"> I agree to <a href="#">privacy policy</a> &amp; <a href="terms-condition.html">terms</a></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                    <button to="/login" className="btn btn-primary btn-block" id="submit-btn" onClick={(e) => {
                                            createAccount(e);
                                            }} >Create Account</button> 
                                                    </div>
                                                </div>
                                                <div className="col-12 text-center">
                                                    {/* <div className="small mb-3">or signup with</div>
                                                    <ul className="btn-list btn-list-inline gx-2">
                                                        <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-facebook-f"></em></a></li>
                                                        <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-google"></em></a></li>
                                                        <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-apple"></em></a></li>
                                                    </ul> */}
                                                    <p className="mt-4">Already have an account? <Link to ='/login'>Login</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    );
  };
  
  export default Registration;
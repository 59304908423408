import React from 'react';

function Prepare({ setStatus }){
    setTimeout(() => {
        setStatus('OK');
    }, 10000);

    return(
        <div className="my-auto">
            <div className="container">
                <div className="row g-gs justify-content-center">
                    <div className="col-md-7 col-lg-6 col-xl-5">
                        <div className="card border-0 shadow-sm rounded-4">
                            <div className="card-body">
                                <h4>Prepare</h4>
                                {sessionStorage.getItem('isDemo') == 'true'? <h4>准备阶段</h4>:<></>}
                                <p>In this question you will be asked to give your opinion on a familiar topic.</p>
                                <p>After you hear the question, you will have 15 seconds to prepare your response and 45 seconds to speak.</p>
                                {sessionStorage.getItem('isDemo') == 'true'? 
                                <>
                                    <p>请对下一个页面中的问题发表你的看法。</p>
                                    <p>听到问题后，你将有15秒的时间准备你的回答，然后你将有有45秒的时间进行发言。</p>
                                </>:<></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prepare;

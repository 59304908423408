import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../Constants/constants';
import { refreshToken } from '../Auth/Authentication';
import AudioRecorder from './AudioRecorder';

import frame from "../../assets/fbimg/frame.svg";
import ellipse7 from "../../assets/fbimg/ellipse-7.png";
import send_icon from "../../assets/fbimg/vector.png";

function Conversation({topic_id, dialogue_id, arg_messages}) {
    require('../../assets/css/ChatBox.css');
    require('../../assets/css/Loader.css');
    const [dialogue, setDialogueID] = useState('' + dialogue_id);
    const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const chatboxRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [customText, setCustomText] = useState(null);

    const suggested_texts = [
        'How to prepare for TOEFL Speaking',
        'Provide an sample answer',
        'Give a more detailed feedback on grammar errors',
        'How to prepare for similar questions'
    ];

    const process = async (formData, endpoint) => {
        console.log("process start");
        try {
            console.log('FormData contents:', Array.from(formData.entries()));
            const response = await axios.post(API_URL + '/mock' + endpoint, formData, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('FormData contents:', Array.from(formData.entries()));

            if (response.status === 200){
                console.log("OK");
                console.log(response);
                setLoading(false);
                console.log("stop loading");
                const data = response.data; // use the result
                console.log(data);
                displayTranscriptAndResponse(data.transcript, data.feedback);
            } else if (response.status === 202) { // status code changed to 202 (Accepted)
                console.log(response.data);
                let result = null;
    
                // Polling until the job is complete or there's an error
                console.log("process get");
                while (true) {
                    const resultResponse = await axios.get(API_URL + '/mock/result/' + response.data.job_id, {
                        headers: {
                            'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                        }
                    });
    
                    if (resultResponse.status === 200) {
                        console.log(response.data.dialogue_id);
                        if (resultResponse.data.status === 'complete') {
                            console.log(result);
                            result = resultResponse.data;
                            break;
                        } else if (resultResponse.data.status === 'error') { 
                            console.error('Error processing:', resultResponse.data.message);
                            return;
                        }
                    } else if (resultResponse.status === 202) {
                        console.log('Job started but not yet completed.');
                    } else {
                        console.error('Error getting job result:', resultResponse.statusText);
                        return;
                    }
    
                    // Wait for a moment before the next polling
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }

                console.log(result);
                setLoading(false);
                console.log("stop loading");
                const data = result; // use the result
            } else {
                console.error('Error uploading:', response.statusText);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (error.response.data.msg === 'Token has expired') {
                    try {
                        if (refreshToken()) {
                            process(formData, endpoint);
                            return;
                        }
                    } catch (e) {
                        console.error('Error refreshing token:', e);
                    }
                }
            }
            console.error('Error uploading:', error);
        }
    }

    const uploadAudio = async () => {
        if (!sessionStorage.getItem('access')) {
            alert('Please log in');
            return;
        }
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('dialogue_id', dialogue);
        setLoading(true);
    
        process(formData, '/conversation_audio');
    
        setMediaBlobUrl(null);
    }

    const uploadText = async (text) => {
        console.log("uploadTexttt");
        if (!sessionStorage.getItem('access')) {
            alert('Please log in');
            return;
        }
        const formData = new FormData();
        formData.append('text', text);
        formData.append('dialogue_id', dialogue);
        setLoading(true);
        console.log("start loading");
        process(formData, '/conversation_text');
        setMediaBlobUrl(null);
    }

    const displayTranscriptAndResponse = (transcript, aiResponse) => {
        let newMessages = [
            { sender: 'You', text: transcript },
        ];
        
        newMessages.push({ sender: 'AI', text: aiResponse });
        
        setMessages(prevMessages => [...prevMessages, ...newMessages]);
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }

    const AI_first_response = 'What can I do for you? If you have any questions or needs, please let me know and I will do my best to help you.';

    useEffect(() => {
        let newMessages = [];
        newMessages.push({ sender: 'AI', text: AI_first_response });
        for (let i = 2; i < arg_messages.length; i += 2) {
            const arg1 = arg_messages[i];
            const arg2 = arg_messages[i + 1];

            newMessages.push({ sender: 'You', text: arg1 });
            newMessages.push({ sender: 'AI', text: arg2 });
        }
        setMessages(newMessages);
    }, []);

    const [countUpSeconds, setCountUpSeconds] = useState(0);
    const intervalCountUpRef = useRef();

    useEffect(() => {
        if (loading) {
            intervalCountUpRef.current = setInterval(() => {
                setCountUpSeconds((countUpSeconds) => {
                    return countUpSeconds + 1;
                });
            }, 1000);
        } else {
            setCountUpSeconds(0);
            if (intervalCountUpRef.current) {
                clearInterval(intervalCountUpRef.current);
            }
        }
    
        return () => {
            setCountUpSeconds(0);
            if (intervalCountUpRef.current) {
                clearInterval(intervalCountUpRef.current);
            }
        };
    }, [loading]);

    return (
        <section className="">
                {/* <div className="nk-shape bg-shape-blur-d start-50 bottom-0"></div>
                <div className="nk-shape bg-shape-blur-e mt-n12 me-n10 end-50"></div> */}
        <div className="container">
        <div className="section-content">
        <div className="row text-center g-gs justify-content-center">
            {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            </div> */}
    <div className="card rounded-5 border-0 shadow-sm" style={{ overflowY: "auto" }}>
        <div className="chatbox-title">
            <div className="container-icon">
                <img className="" alt="Frame" src={frame} />
            </div>
            <p className="smartprep-TOEFL">
                <span className="text-wrapper-22">-SmartPrep </span>
                <span className="text-wrapper-23">TOEFL</span>
            </p>
        </div>                                
        <div className="chatbox-body">
            <div className= "" id="chatbox" ref={chatboxRef}>
            {messages.map((message, index) => (
                <div key={index} className={`message-wrapper ${message.sender.toLowerCase()}`}>
                    <div className="">
                        {message.sender === 'AI' ? 
                        <div className="message-container">
                            <div className="container-icon ai-icon">
                                <img className="" alt="Frame" src={frame} />
                            </div> 
                            <div className="text-container">
                                <p>{message.text}</p>
                                {/* <div dangerouslySetInnerHTML={{ __html: `<strong>${message.sender}:</strong> ${message.text.replace(/\n/g, '<br />')}` }} /> */}
                            </div>
                        </div>:
                        <div className="message-container">
                        <div className="text-container user">
                            <div dangerouslySetInnerHTML={{ __html: ` ${message.text.replace(/\n/g, '<br />')}` }} />
                        </div>
                        <div className="container-icon user-icon">
                            <img className="" alt="Frame" src={ellipse7} />
                        </div> 
                        </div>
                        }
                    </div>
                </div>
            ))}
            </div>
                    {/* <div className="row g-gs" style={loading ? {display: 'none'} : null}>
                        <div className="col-3">
                            <div className="px-4">
                                <button className="btn btn-outline-primary btn-block rounded-pill" onClick={() => {uploadText(suggested_texts[0])}}>
                                    {suggested_texts[0]}
                                </button>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="px-4">
                                <button className="btn btn-outline-primary btn-block rounded-pill" onClick={() => {uploadText(suggested_texts[0])}}>
                                    {suggested_texts[0]}
                                </button>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="px-4">
                                <button className="btn btn-outline-primary btn-block rounded-pill" onClick={() => {uploadText(suggested_texts[1])}}>
                                    {suggested_texts[1]}
                                </button>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="px-4">
                                <button className="btn btn-outline-primary btn-block rounded-pill" onClick={() => {uploadText(suggested_texts[2])}}>
                                    {suggested_texts[2]}
                                </button>
                            </div>
                        </div>
                    </div> */}

                    <div className="row text-center"  style={loading ? {display: 'none'} : null}>
                        <div className="col-8">
                            <div className="chat-form-group">
                                <div className="chat-form-control-wrap form-sucess">
                                    <textarea name="chat-custom_text" id="chat_custom_text" className="form-control form-control-message" placeholder="Enter your message" required
                                    onInput={e => setCustomText(e.target.value)}/>
                                    <span className="pristine-error form-error-message" style={{display: 'none'}}></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 send-button">
                            <button className="btn rounded-pill btn-secondary" disabled={!customText} onClick={() => {
                                if (customText) {
                                    uploadText(customText);
                                    document.getElementById('chat_custom_text').value = '';
                                    setCustomText(null);
                                }
                            }}>Send message</button>
                        </div>
                    </div>
            <div className="buttons">
            <div className="row g-4">
            <div className='col-8' style={loading ? {display: 'none'} : null}>
                <AudioRecorder invisible={false} setIsRunning={true} uploadAudio={uploadAudio} setMediaBlobUrl={setMediaBlobUrl}/>
            </div>
            <div className='col-12' style={loading ? null : {display: 'none'}}>
                <h3 className='text-center'>Processing. Please Wait... {countUpSeconds}</h3>
            </div>
            <div className='col-4'>
            <p className="text-center mt-4">
                <button className="btn chat-send button-text-wrapper" id="upload" style={loading ? {display: 'none'} : null} onClick={uploadAudio} disabled={mediaBlobUrl == null}>
                <img className="recording-icon" alt="Vector" src={send_icon} />
                Send</button>
            </p>
            </div>
        </div>
        </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    </section>
    );
}

export default Conversation;

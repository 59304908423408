import React from 'react';
import User from '../Auth/User.js';
import { Link } from 'react-router-dom';

function MockHeader({ continueClicked, continueEnabled }){
    return(
        <header className="nk-header bg-darker is-dark has-mask">
            <div className="nk-mask bg-pattern-dot-white-sm bg-blend-bottom"></div>
            <div className="nk-header-main nk-menu-main is-transparent will-shrink on-dark ignore-mask">
                <div className="container">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            {/* Please add logo here */}
                            <Link to="/mock" style={{color: '#dce3ed'}}>
                                <span className="nk-menu-text">SmartPrep</span>
                            </Link>
                        </div>
                        <ul className="flex-lg-row-reverse">
                            <li><button className="btn btn-outline-primary rounded-pill"
                            disabled={!continueEnabled}
                            onClick={continueClicked}>CONTINUE</button></li>
                            <li style={{ display: ('none') }}><User/></li>
                        </ul>
                        {/* <div className="nk-header-toggle">
                            <button className="btn btn-light btn-icon header-menu-toggle">
                                <em className="icon ni ni-menu"></em>
                            </button>
                        </div>
                        <nav className="nk-header-menu nk-menu">
                            <ul className="nk-menu-list me-auto"></ul>
                            <ul className="nk-menu-buttons flex-lg-row-reverse">
                                <li><a href="#" className="btn btn-outline-primary rounded-pill">CONTINUE</a></li>
                                <li style={{ display: ('none') }}><User refreshToken={refreshToken}/></li>
                            </ul>
                        </nav> */}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MockHeader;

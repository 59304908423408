import React, { useState, useRef, useEffect } from 'react';
import '../../assets/css/ChatBox.css'; // For styling
import '../../assets/css/Loader.css';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { API_URL } from '../../Constants/constants';
import { refreshToken } from '../Auth/Authentication';
import { useNavigate } from 'react-router-dom';
import AudioRecorder from './AudioRecorder';
import { CircularProgress } from '@mui/material';

export const processAudio = async (formData, setLoading, resetTimer, navigate) => {
    try {
        console.log('FormData contents:', Array.from(formData.entries()));

        const response = await axios.post(API_URL + '/mock/new_mock', formData, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.status === 200) {
            const dialogueId =response.data.dialogue_id;
            console.log(response.data);

            setLoading && setLoading(false);
            resetTimer && resetTimer();
            navigate(`/feedback/${dialogueId}`);
        } else if (response.status === 204) {
            console.error('Error uploading audio:', response.statusText);
        } else {
            console.error('Error uploading audio:', response.statusText);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (error.response.data.msg === 'Token has expired') {
                try {
                    if (refreshToken()) {
                        processAudio(formData, setLoading, resetTimer, navigate);
                        return;
                    }
                } catch (e) {
                    console.error('Error refreshing token:', e);
                }
            }
        }
        console.error('Error uploading audio:', error);
        error.response ?
        alert(`Error uploading audio.\nResponse: ${JSON.stringify(error.response.data)}`)
        : alert(`Error uploading audio.\n${error.message}`);
        navigate('/mock');
    }
}

function ChatBoxNew({ topic_id }) {
    const [topic, setTopic] = useState('' + topic_id);
    const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
    const [topics, setTopics] = useState([]);
    const navigate = useNavigate();
    const fetchTopics = async () => {
        const response = await axios.get(API_URL + '/topic/all');
        setTopics(response.data);
    };

    const uploadAudio = async () => {
        if (!sessionStorage.getItem('access')) {
            alert('Please log in');
            return;
        }
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('topic', topic);
        setLoading(true);
    
        processAudio(formData, setLoading, resetTimer, navigate);
    
        setMediaBlobUrl(null);
    }

    // Timer Logic
    const [remainingSeconds, setRemainingSeconds] = useState(45);
    const [countDownSeconds, setCountDownSeconds] = useState(15);
    const [countUpSeconds, setCountUpSeconds] = useState(0);
    const [countDown, setCountDown] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const intervalRef = useRef();
    const intervalCountDownRef = useRef();
    const intervalCountUpRef = useRef();

    const [uploadStarted, setUploadStarted] = useState(false);

    useEffect(() => {
        if (isRunning) {
          intervalRef.current = setInterval(() => {
            setRemainingSeconds((remainingSeconds) => {
              if (remainingSeconds > 0) {
                return remainingSeconds - 1;
              } else {
                setTimeout(() => {
                    document.getElementById("stop").click();
                  }, 10);
                setIsRunning(false); 
                setTimeout(() => {
                    document.getElementById("upload").click();
                  }, 1500);
                return 0;
              }
            });
          }, 1000);
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }
    
        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        };
    }, [isRunning]);
    const processingSteps = [
      "Evaluating your grammar...",
      "Checking coherence...",
      "Assessing logic...",
      "Analyzing word choice...",
      "Reviewing your examples...",
      "Testing pronunciation..."
      ];
    const [currentStep, setCurrentStep] = useState(0);
    useEffect(() => {
      if (uploadStarted) {
          const interval = setInterval(() => {
              setCurrentStep(prev => (prev + 1) % processingSteps.length);
          }, (20000 / processingSteps.length)); // Changes message every fraction of the total time
  
          return () => clearInterval(interval);
      }
  }, [uploadStarted]);

    useEffect(() => {
        if (uploadStarted) {
            intervalCountUpRef.current = setInterval(() => {
                setCountUpSeconds((countUpSeconds) => {
                    return countUpSeconds + 1;
                });
            }, 1000);
        } else {
          if (intervalCountUpRef.current) {
            clearInterval(intervalCountUpRef.current);
          }
        }
    
        return () => {
          if (intervalCountUpRef.current) {
            clearInterval(intervalCountUpRef.current);
          }
        };
    }, [uploadStarted]);

    useEffect(() => {
        if (countDown) {
            intervalCountDownRef.current = setInterval(() => {
            setCountDownSeconds((countDownSeconds) => {
              if (countDownSeconds > 0) {
                return countDownSeconds - 1;
              } else {
                setCountDown(false);
                if (!isRunning) {
                    setTimeout(() => {
                        document.getElementById("record").click();
                    }, 10);
                }
              }
            });
          }, 1000);
        } else {
          if (intervalCountDownRef.current) {
            clearInterval(intervalCountDownRef.current);
          }
        }
    
        return () => {
          if (intervalCountDownRef.current) {
            clearInterval(intervalCountDownRef.current);
          }
        };
    }, [countDown]);
    
    // Fetch topics on component mount
    useEffect(() => {
        fetchTopics();
    }, []);
    
    useEffect(() => {
        if (topics.length >= 1) {
            setCountDown(true);
        }
    }, [topics]);

    // Loading Effect
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (loading) {
            setUploadStarted(true);
        }
    }, [loading]);
    const minutes = String(Math.floor((isRunning ? remainingSeconds : countDownSeconds) / 60)).padStart(2, "0");
    const seconds = String((isRunning ? remainingSeconds : countDownSeconds) % 60).padStart(2, "0");

    const resetTimer = () => {
        setRemainingSeconds(45);
    };

    return (
        <section className="section section-0 has-shape my-auto">
                <div className="nk-shape bg-shape-blur-d start-50 bottom-0"></div>
                <div className="nk-shape bg-shape-blur-e mt-n12 me-n10 end-50"></div>
        <div className="container">
            <div className="section-content">
                <div className="row text-center g-gs justify-content-center">
        


                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4">{topics.length >= 1 ? topics[(topic_id - 1)].text : null}</Typography>
                    </div>
                    {!isRunning && !countDown ?
                    null
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        {sessionStorage.getItem('isDemo') == 'true'? 
                        <>
                          <Typography variant="h6">{countDown ? 'Preparation Time' : 'Record Time'}</Typography>
                          <Typography variant="h6">{countDown ? '你拥有15秒钟的准备时间' : '请完成45秒的题目作答'}</Typography>
                        </>:<Typography variant="h6">{countDown ? 'Preparation Time' : 'Record Time'}</Typography>}
                    </div>
                    }
                    {!isRunning && !countDown ?
                    null
                    :
                    <div className='timer' style={uploadStarted ? {display: 'none'} : {display: 'block'}}>
                        <span className="timer__part">{minutes}</span>
                        <span className="timer__part">:</span>
                        <span className="timer__part">{seconds}</span>
                    </div>
                    }
                    {/* {uploadStarted &&
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4">Processing. Please wait... {countUpSeconds}</Typography>
                    </div>} */}
                    {uploadStarted &&
                      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <CircularProgress size={60} />
                          <Typography variant="h6" style={{ marginTop: 15 }}>
                              {processingSteps[currentStep]}
                          </Typography>
                          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
                              Please wait... {countUpSeconds}s/60s
                          </Typography>
                          {sessionStorage.getItem('isDemo') == 'true'? 
                          <Typography variant="h6" style={{ marginTop: 15 }}>
                          {'请稍等一会，你的错题报告即将生成完毕！'}
                        </Typography>
                        :<></>}
                      </div>}

                    <AudioRecorder invisible={true} setIsRunning={setIsRunning} uploadAudio={uploadAudio} setMediaBlobUrl={setMediaBlobUrl}/>
                </div>
            </div>
        </div>
    </section>
    );
}

export default ChatBoxNew;

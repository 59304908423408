import React from "react";
import { Link } from "react-router-dom";
import gif from "../../assets/images/LandingPage.gif";
import Footer from "../Footer";
import axios from 'axios';
import { API_URL } from '../../Constants/constants';
import { useNavigate} from 'react-router-dom';

function FirstTimeWelcomePage() {
  require("../../assets/css/style-s3.css");
  require("../../assets/css/WelcomePage.css");
  const navigate = useNavigate();
  
  const handleLogin = async (e, login, password, rememberMe) => {
    if (e) e.preventDefault();
    try {
      const response = await axios.post(API_URL + '/auth/login', {
        login: login,
        password: password,
      });

      if (response.status === 200) {
        sessionStorage.setItem('access', response.data.user.access);
        sessionStorage.setItem('refresh', response.data.user.refresh);
        sessionStorage.setItem('username', response.data.user.username);
        sessionStorage.setItem('email', response.data.user.email);
        sessionStorage.setItem('isDemo', true);
        if (rememberMe) {
          localStorage.setItem('access', response.data.user.access);
          localStorage.setItem('refresh', response.data.user.refresh);
        }
        navigate('/testmock');
      } else {
        alert("Invalid credentials");
      }
    } catch (err) {
      var err_msg = 'An error has occurred. Please try again later.\nIf this issue persists, please contact us with the following message.\n';
      if (err.response) {
        if (err.response.data && err.response.data.error) {
          err_msg = err.response.data.error;
        } else {
          err_msg += JSON.stringify(err.response);
        }
      } else {
        err_msg += JSON.stringify(err.message);
      }
      console.error(err_msg);
      console.error(err);
      alert(err_msg);
    }
  }

  return (
    <div className="nk-app-root">
      <main className="nk-pages">
        <section className="section has-mask min-vh-100 d-flex flex-column">
          <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
          <div className="container my-auto">
            <div className="secton-content">
              <div className="row g-gs justify-content-center">
                <div className="col-md-12 col-lg-10 col-xl-9 col-xxl-8">
                  <div className="mt-4">
                    <h1 className="title chinese-title chinesefont">
                      SmartPrep
                    </h1>
                    <h2 className="chinesefont">你的托福口语智能助手</h2>
                    <h3 className="chinesefont">
                      还在苦恼托福口语没法提分?一分钟，帮你找出所有核心问题!
                    </h3>
                    <ul className="btn-list btn-list-inline g-0 justify-content-center">
                      <li>
                        <button
                          onClick={(e) => {
                            handleLogin(e, 'Demo@test.com', 'Demo@test.com', false);
                          }}
                          className="btn btn-primary cute-button"
                        >
                          立即体验
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/mock"
                          className="btn btn-primary cute-button"
                        >
                          已有账号？直接登录
                        </Link>
                      </li>
                    </ul>
                    <img
                      className="landinggif"
                      src={gif}
                      alt="Landing Page GIF"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default FirstTimeWelcomePage;

import React from 'react';

function Footer({ fix, dark }){
    // const fix_at_bottom = {
    //     position: 'absolute !important',
    //     left: 0,
    //     bottom: 0,
    //     right: 0
    // };
    require('../assets/css/Footer.css');

    return(
<footer id='footer' className={"nk-footer " + (dark ? "bg-darker " : "") + (fix ? "fix-at-bottom" : "")}>
            <div className="section section-sm section-top-0">
                <div className="container">
                    {fix & dark?<></>:<div className="row g-5">
                        {/* <div className="col-xl-4 col-lg-7 col-md-9 me-auto">
                            <div className="block-text pe-xxl-5">
                                <a href="index.html" className="logo-link mb-4">
                                    <div className="logo-wrap">
                                        <img className="logo-img logo-light" src="images/logo-green.png" srcSet="images/logo-green2x.png 2x" alt=""/>
                                        <img className="logo-img logo-dark" src="images/logo-green-dark.png" srcSet="images/logo-green-dark2x.png 2x" alt=""/>
                                    </div>
                                </a>
                                <p>CopyGen is an artificial intelligence trained to automate important tasks such as writing optimized product descriptions, high-converting ad copy, blog outlines, and more!</p>
                            </div>
                        </div> */}
                        <div className="col-xl">
                            <div className="row g-gs">
                                {/* <div className="col-lg-3 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading">Company</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="#">About Us</a></li>
                                            <li><a className="link-base" href="#">Careers</a></li>
                                            <li><a className="link-base" href="#">Community</a></li>
                                            <li><a className="link-base" href="#">Creator Program</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading">Use Case</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="#">Blog writing</a></li>
                                            <li><a className="link-base" href="#">Social media Ads</a></li>
                                            <li><a className="link-base" href="#">Creative writing</a></li>
                                            <li><a className="link-base" href="#">Magic command</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading">帮助我们变得更好</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="/userfeedback">报告一个小Bug🐞</a></li>
                                            <li><a className="link-base" href="/userfeedback">告诉我们你想要的新功能🌟</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5 col-sm-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading">联系方式</h6>
                                        <ul className="list gy-3">
                                            <li><em className="icon text-primary fs-5 ni ni-mail-fill"></em> <span>smartprep666@gmail.com</span></li>
                                            {/* <li><em className="icon text-primary fs-5 ni ni-call-alt-fill"></em> <span>+(642) 342 762 44</span></li>
                                            <li><em className="icon text-primary fs-5 ni ni-map-pin-fill"></em> <span>442 Belle St Floor 7, San Francisco, AV 4206</span></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section section-0">
                <hr className="border-opacity-25 border-primary m-0"/>
                <div className="container">
                    <div className="py-4">
                        <div className="row">
                            <div className="col-md">
                                <ul className="list list-row gx-4 justify-content-start justify-content-md-end">
                                    <li><a href="#" className="link-primary">Terms</a></li>
                                    <li><a href="#" className="link-primary">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>}
                </div>
            </div>
        </footer>
    );
}
        
export default Footer;

import React from 'react';
import { Link} from "react-router-dom";

function WelcomPage(){
    require('../../assets/css/style-s3.css');
    return (
        <div className="nk-app-root">
        <main className="nk-pages">
            <section className="section has-mask min-vh-100 d-flex flex-column">
                <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                <div className="container my-auto">
                    <div className="section-content">
                        <div className="row g-gs justify-content-center">
                            <div className="col-md-9 col-lg-7 col-xl-6 col-xxl-5">
                                <div className="mt-4">
                                    <h3 className="title">Woohoo! We are glad to meet you!</h3>
                                    <p>感谢您成为我们珍贵的首批测试用户，以下有几点使用说明：</p>
                                    <p>As we diligently work on developing our app, we kindly request your cooperation in following a few essential steps to ensure the best possible experience:</p>
                                    <p>请从电脑端浏览器访问以获得最好效果。</p>
                                    <p>To access our pages, we recommend using a desktop web browser rather than a mobile device.</p>
                                    <p>在使用结束后，我们希望您能够在测试群中留下宝贵的意见和建议。您也可以通过邮箱leewenxuan2003@outlook.com向我们提供反馈。再次感谢您的时间。</p>
                                    <p>We sincerely appreciate your understanding and value your feedback! You can provide feedback to us via email at leewenxuan2003@outlook.com. Thank you again for your time. We look forward to hearing from you soon. Cheers!</p>
                                    <ul className="btn-list btn-list-inline g-0">
                                        <li><Link to="/mock" className="btn btn-primary">Get Started</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    );
}

export default WelcomPage;

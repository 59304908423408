import React, { useState, useEffect }from 'react';
import '../assets/css/App.css';
import WelcomePage from './Landing/WelcomePage';
import FirstTimeWelcomePage from './Landing/FirstTimeWelcomePage';



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('access')) {
          setIsLoggedIn(true);
      }
  }, []);

  return (
    <div className="App">
        <FirstTimeWelcomePage />
    </div>
  );
}

export default App;

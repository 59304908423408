import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import MockModePage from './Components/Mock/MockModePage';
import MockIndex from './Components/Mock/MockIndex';
import Authentication from './Components/Auth/Authentication';
import FeedbackPage from './Components/Mock/FeedbackPage';
import DashboardPage from './Components/Profile/DashboardPage';
import TestMock from './Components/Mock/TestMock';
import DemoLogIn from './Components/Landing/DemoLogIn';
import UserFeedback from './Components/UserFeedback';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AdminPage from './Components/Admin/AdminPage';
import UploadModePage from './Components/Mock/UploadModePage';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (/iPhone/i.test(window.navigator.userAgent)) {
  root.render(<h1>请用电脑端Chrome浏览器打开以正常使用</h1>);
}
else {

root.render(
    <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<App />}/>
          <Route exact path="/admin" element={<AdminPage />} />
          <Route exact path="/mock/upload/:id" element={<UploadModePage />} />
          <Route exact path="/mock" element={<MockIndex />} />
          <Route exact path="/mock/:id" element={<MockModePage />} />
          <Route exact path="/feedback/:dialogueID" element={<FeedbackPage />} />
          <Route exact path="/login" element={<Authentication />} />
          <Route exact path="/register" element={<Authentication />} />
          <Route exact path="/dashboard" element={<DashboardPage />} />
          <Route exact path="/testmock" element={<TestMock />} />
          <Route exact path="/userfeedback" element={<UserFeedback />} />
          <Route/>
    </Routes>
    </BrowserRouter>
);

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import MockHeader from './MockHeader';
import MockFooter from './MockFooter';
import ChatBoxNew from './ChatBoxNew.js'
import RecordTest from './RecordTest';
import Prepare from './Prepare';
import UploadMode from './UploadMode';

function TestMock({ uploadMode }) {
    require('../../assets/css/style-s2.css');
    const [status, setStatus] = useState('');
    const id = useParams().id;
    const navigate = useNavigate();

    const continueClicked = () => {
        if (status === '') {
            setStatus('Recorded');
        } else if (status === 'Recorded') {
            setStatus('OK');
        } else {
            console.error('Logic error');
        }
    }

    return (
        <div>
            <div className="nk-app-root no-scroll">
                <main className="nk-pages">
                    <div className="min-vh-100 d-flex flex-column has-mask">
                        <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                        <MockHeader continueClicked={continueClicked} continueEnabled={status !== 'OK' && !uploadMode} />
                        {uploadMode ?
                        <UploadMode topic_id={id} />
                        : !status ?
                        <RecordTest setStatus={setStatus}/>
                        : status === 'Recorded' ?
                        <Prepare setStatus={setStatus}/>
                        : <ChatBoxNew topic_id={1}/>}
                        <MockFooter />
                    </div>
                </main>
            </div>
        </div>
    );
}

export default TestMock;

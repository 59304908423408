import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Constants/constants';
import { handleLogout } from '../Auth/User';
import { refreshToken } from '../Auth/Authentication';
import { Card } from '../Mock/Cards';
import LineChart from './LineChart';

const retrieveStats = async (stat, data, setFunction, setIAmAdmin) => {
    try {
        const response = await axios.get(API_URL + '/admin/stats/' + stat, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
            }
        });
        setIAmAdmin(true);
        setFunction(response.data[data]);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (error.response.data.msg === 'Token has expired') {
                try {
                    if (refreshToken()) {
                        retrieveStats();
                        return;
                    }
                } catch (e) {
                    console.error('Error refreshing token:', e);
                }
            }
        }
        else if (error.response && error.response.status === 403) {
            handleLogout();
            return;
        }
      console.error('Error:', error);
    }
};

function UserCount() {
    const [userCount, setUserCount] = useState(null);
    const [iAmAdmin, setIAmAdmin] = useState(false);

    useEffect(() => {
        retrieveStats('users', 'user_count', setUserCount, setIAmAdmin);
    });

    if (!iAmAdmin) {
        return (<div />);
    }

    return (
        <Card title="Users" description={userCount} icon="ni-globe" text_bg="text-bg-info-soft" normal={true} />
    );
}

function DialogueCount() {
    const [dialogueCount, setDialogueCount] = useState(null);
    const [iAmAdmin, setIAmAdmin] = useState(false);

    useEffect(() => {
        retrieveStats('dialogues', 'dialogue_count', setDialogueCount, setIAmAdmin);
    });

    if (!iAmAdmin) {
        return (<div />);
    }

    return (
        <Card title="Dialogue" description={dialogueCount} icon="ni-edit" text_bg="text-bg-info-soft" normal={true} />
    );
}

function MessageCount() {
    const [messageCount, setMessageCount] = useState(null);
    const [iAmAdmin, setIAmAdmin] = useState(false);

    useEffect(() => {
        retrieveStats('messages', 'message_count', setMessageCount, setIAmAdmin);
    });

    if (!iAmAdmin) {
        return (<div />);
    }

    return (
        <Card title="Messages" description={messageCount} icon="ni-comments" text_bg="text-bg-info-soft" normal={true} />
    );
}

function AdminPage() {
    require('../../assets/css/style-s3.css');
    const navigate = useNavigate();

    useEffect(() => {
        if (!sessionStorage.getItem('access')) {
            navigate('/login');
        }
    });

    if (!sessionStorage.getItem('access')) {
        return (<div />);
    }

    return (
        <div>
            <div className="nk-app-root ">
                <Header />
                <main className="nk-pages">
                    <section className="section section-bottom-0 has-shape has-mask">
                    <div className="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                    <div className="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                    <div className="container">
                        {/* <div className="section-head">
                            <div className="row justify-content-center text-center">
                                <div className="col-xl-8">
                                    <h1 className="title">placeholder</h1>
                                </div>
                            </div>
                        </div> */}
                        <div className="section-content">
                            <div className="row text-center g-gs">
                                <UserCount />
                                <DialogueCount />
                                <MessageCount />
                                <LineChart endpoint="/plot/users" name="User Chart" mode="TOTAL"/>
                                <LineChart endpoint="/plot/dialogues" name="Dialogue Chart" mode="TOTAL"/>
                                <LineChart endpoint="/plot/messages" name="Message Chart" mode="TOTAL"/>
                            </div>
                        </div>
                    </div>
                    </section>
                </main>
                <Footer />
            </div>
        </div>
    );
}

export default AdminPage;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = ({ handleLogin }) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const handleLoginInput = () => {
      setPassword(document.getElementById('toggle-password').value);
      setLogin(document.getElementById('emailorusername').value);
    };

    useEffect(() => {
        if (sessionStorage.getItem('access')) {
            navigate('/mock');
        }
    }, [login, password]);

    return (
      <div className="nk-app-root">
          <main className="nk-pages">
              <div className="min-vh-100 d-flex flex-column has-mask">
                  <div className="nk-mask bg-pattern-dot bg-blend-around"></div>
                  {/* <div className="text-center mt-6 mb-4">
                      <a href="index.html" className="logo-link">
                          <div className="logo-wrap">
                              <img className="logo-img logo-light" src="images/logo.png" srcSet="images/logo2x.png 2x" alt="" />
                              <img className="logo-img logo-dark" src="images/logo-dark.png" srcSet="images/logo-dark2x.png 2x" alt="" />
                          </div>
                      </a>
                  </div> */}
                  <div className="my-auto">
                      <div className="container">
                          <div className="row g-gs justify-content-center">
                              <div className="col-md-7 col-lg-6 col-xl-5">
                                  <div className="card border-0 shadow-sm rounded-4">
                                      <div className="card-body">
                                          <h4 className="mb-3">Welcome Back!</h4>
                                          <form onSubmit={(e) => {
                                            handleLoginInput(); 
                                            handleLogin(e, login, password, rememberMe)}} noValidate>
                                              <div className="row g-4">
                                                  <div className="col-12">
                                                      <div className="form-group">
                                                          <label className="form-label" htmlFor="emailorusername">Email or Username</label>
                                                          <div className="form-control-wrap">
                                                              <input type="email" name="emailorusername" id="emailorusername" className="form-control form-control-lg" placeholder="Enter Email or Username" required
                                                              onInput={e => setLogin(e.target.value)}/>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-12">
                                                      <div className="form-group">
                                                          <label className="form-label" htmlFor="toggle-password">Password</label>
                                                          <div className="form-control-wrap">
                                                              <input type="password" name="password" id="toggle-password" className="form-control form-control-lg" placeholder="Enter Password" required
                                                              onInput={e => setPassword(e.target.value)}/>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-12">
                                                      <div className="d-flex flex-wrap justify-content-between has-gap g-3">
                                                          <div className="form-group">
                                                              <div className="form-check form-check-sm">
                                                                  <input className="form-check-input" type="checkbox" value="" id="rememberMe" checked={rememberMe} onChange={e => setRememberMe(e.target.checked)}/>
                                                                  <label className="form-check-label" htmlFor="rememberMe"> Remember Me </label>
                                                              </div>
                                                          </div>
                                                          <a href="reset-s2.html" className="small">Forgot Password?</a>
                                                      </div>
                                                  </div>
                                                  <div className="col-12">
                                                      <div className="form-group">
                                                          {/* <Link to="/mock" className="btn btn-primary btn-block" type="submit" id="submit-btn">Login</Link> */}
                                                          <button className="btn btn-primary btn-block" type="submit" id="submit-btn">Login</button>
                                                      </div>
                                                  </div>
                                                  <div className="col-12 text-center">
                                                      {/* <div className="small mb-3">or login with</div>
                                                      <ul className="btn-list btn-list-inline gx-2">
                                                          <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-facebook-f"></em></a></li>
                                                          <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-google"></em></a></li>
                                                          <li><a className="btn btn-light btn-icon" href="#"><em className="icon fs-5 ni ni-apple"></em></a></li>
                                                      </ul> */}
                                                      <p className="mt-4">Don't have an account? <Link to = '/register'>Register</Link></p>
                                                      {/* <p className="mt-4">Don't have an account? <a>Register</a></p> */}
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </div>
    );
  };
  
  export default Login;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Desmos from 'desmos'
import { API_URL } from '../../Constants/constants';
import { handleLogout } from '../Auth/User';
import { refreshToken } from '../Auth/Authentication';

const retrievePlot = async (endpoint, setJSON) => {
    try {
        const response = await axios.get(API_URL + '/admin' + endpoint, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('access'),
            }
        });
        setJSON(response.data);
        console.log(response.data);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (error.response.data.msg === 'Token has expired') {
                try {
                    if (refreshToken()) {
                        retrievePlot();
                        return;
                    }
                } catch (e) {
                    console.error('Error refreshing token:', e);
                }
            }
        }
        else if (error.response && error.response.status === 403) {
            handleLogout();
            return;
        }
      console.error('Error:', error);
    }
};

/* MODE: DIFF, TOTAL. */
function LineChart(prop) {
    const {endpoint, name, mode} = prop;
    const [calculator, setCalculator] = useState(null);
    const [json, setJSON] = useState(null);
    const [startingDate, setStartingDate] = useState(null);

    const add_data_line_chart = () => {
        if (!calculator || !json || json.data.length <= 0) {
            return;
        }

        /* Hardcode this date */
        setStartingDate('2023-06-21');

        var date = new Date('2023-06-21');
        var diffArray = [0];
        var thisDate;
        var data;
        for (let i = 0; i < json.data.length; ++i) {
            while (date.toUTCString() !== (thisDate = new Date(json.data[i].date)).toUTCString()) {
                date.setDate(date.getDate() + 1);
                diffArray.push(0);
            }
            diffArray.push(json.data[i].count);
            date.setDate(date.getDate() + 1);
        }
        let today = new Date((new Date()).toISOString().split('T')[0]);
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        if (date.toUTCString() !== tomorrow.toUTCString()) {
            while (date.toUTCString() !== today.toUTCString()) {
                date.setDate(date.getDate() + 1);
                diffArray.push(0);
            }
        }
        diffArray = diffArray.slice(1);

        const totalNumArray = [diffArray[0]];
        for (let i = 1; i < diffArray.length; i++) {
            const totalNum = totalNumArray[i - 1] + diffArray[i];
            totalNumArray.push(totalNum);
        }

        switch (mode) {
            case "TOTAL":
                data = totalNumArray;
                break;
            case "DIFF":
                data = diffArray;
                break;

            default:
                data = totalNumArray;
                break;
        }
        console.log(data);

        for (let i = 0; i < data.length; ++i) {
            calculator.setExpression({ id: `y_${i}`, latex: String.raw`y_{${i}}=${data[i]}` });
        }
        for (let i = 0; i < data.length - 1; ++i) {
            if (data[i] !== data[i + 1]) {
                calculator.setExpression({ id: `graph_${i}`, latex: String.raw`(y-y_{${i+1}})/(y_{${i}}-y_{${i+1}})=(x-${i+1})/(${i}-${i+1})\left\{${i}<x\le${i+1}\right\}` });
            } else {
                calculator.setExpression({ id: `graph_${i}`, latex: String.raw`y=y_{${i}}\left\{${i}<x\le${i+1}\right\}` });
            }
        }
    };

    useEffect(() => {
        var elt = document.getElementById('calculator ' + name);
        var calculator = Desmos.GraphingCalculator(elt, {expressions: false});
        setCalculator(calculator);
        retrievePlot(endpoint, setJSON);
    }, []);

    useEffect(() => {
        add_data_line_chart();
    }, [calculator, json]);

    return (
        <div className="col-md-12 col-xl-12" style={!json ? {display: 'none'} : null}>
            <h1 className='text-center'>{`${name} (Starting from: ${startingDate})`}</h1>
            <div id={"calculator " + name} style={{width: '100%', 'aspect-ratio': '16/9'}}></div>
        </div>
    );
}

export default LineChart;

import React, { useState, useRef } from 'react';
import AudioRecorderPolyfill from "audio-recorder-polyfill";

import recording_icon from "../../assets/fbimg/group-13.png";
import audio_icon from "../../assets/fbimg/group-19.png";

/* To use mpeg encoder (better compression), uncomment these lines. */
// import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
 
// AudioRecorderPolyfill.encoder = mpegEncoder;
// AudioRecorderPolyfill.prototype.mimeType = 'audio/mpeg';

window.MediaRecorder = AudioRecorderPolyfill;

function AudioRecorder(prop){
    require('../../assets/css/AudioRecorder.css');
    const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const recorderRef = useRef(null);

    const handleStartRecording = () => {
        setIsRecording(true);

        // Request permissions to record audio
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            const recorder = new MediaRecorder(stream);

            // Set record to <audio> when recording will be finished
            recorder.addEventListener('dataavailable', e => {
                setMediaBlobUrl(URL.createObjectURL(e.data));
                prop.setMediaBlobUrl && prop.setMediaBlobUrl(URL.createObjectURL(e.data));
            });

            // Start recording
            recorder.start();

            recorderRef.current = recorder;
        })
    };
  
    const handleStopRecording = () => {
        setIsRecording(false);

        // Stop recording
        recorderRef.current.stop()
        // Remove “recording” icon from browser tab
        recorderRef.current.stream.getTracks().forEach(i => i.stop())
    };

    const playRecordedAudio = async () => {
        const button = document.getElementById('play-recorded');
        button.disabled = true;
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioURL = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioURL);
        await new Promise(res=>{
            audio.play()
            audio.onended = res
        });
        button.disabled = false;
        prop.setCanStart && prop.setCanStart(true);
    };

    return(
        <div style={prop.invisible ? {display: 'none'} : null}>
            <div style={{display: 'none'}} >
                <button className="btn rounded-pill btn-primary" id= "record" onClick={() => {
                    setTimeout(() => {
                        setMediaBlobUrl(null);
                        prop.setIsRunning && prop.setIsRunning(true);
                        handleStartRecording();
                    }, 1);
                }} disabled={isRecording}>Invisible Record Button</button>
                <button className="btn rounded-pill btn-primary" id= "stop" onClick={() => {
                    setTimeout(() => {
                        handleStopRecording();
                        prop.setIsRunning && prop.setIsRunning(false);
                    }, 1);
                }} disabled={!isRecording}>Invisible Stop Button</button>
                <button className="btn rounded-pill btn-primary" id= "upload" onClick={() => {
                    prop.uploadAudio && prop.uploadAudio();
                }} disabled={mediaBlobUrl == null}>Invisible Upload Button</button>
            </div>
            <div className="row g-4">
                <div className="col-6">
                    <p className="text-center mt-4">
                    <button className="btn  text-wrapper" id= "record-stop" onClick={() => {
                        if (!isRecording) {
                            setMediaBlobUrl(null);
                            handleStartRecording();
                        }
                        else {
                            handleStopRecording();
                        }
                    }}><img className="group-56" alt="Group" src={recording_icon} />{isRecording ? 'Stop Recording' : 'Start Recording'}</button>
                    </p>
                </div>
                <div className="col-6">
                    <p className="text-center mt-4">
                        <button className="btn chat-play-audio text-wrapper" id="play-recorded" onClick={() => {
                            playRecordedAudio();
                        }} disabled={mediaBlobUrl == null}><img className="group-56" alt="Group" src={audio_icon} />Play Audio</button>
                    </p>
                    {/* <p className="text-center mt-4">
                    <button className="btn rounded-pill btn-primary" id= "restart" onClick={() => {
                        setMediaBlobUrl(null);
                    }} disabled={isRecording || mediaBlobUrl == null}>Restart</button>
                    </p> */}
                </div>
            </div>
            {/* <div className="row g-4">
                <div className="col-12">
                    <p className="text-center mt-4">
                        <button className="btn rounded-pill btn-info" id= "play-recorded" onClick={() => {
                            playRecordedAudio();
                        }} disabled={mediaBlobUrl == null}>Play Audio</button>
                    </p>
                </div>
            </div> */}
        </div>
    );
}

export default AudioRecorder;

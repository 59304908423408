import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { API_URL } from '../../Constants/constants';
import { processAudio } from './ChatBoxNew';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const FileUploadButtons = ({topic, setUploadStarted}) => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        console.log('Selected file:', selectedFile);
    };

    const uploadAudio = async (file) => {
        if (!sessionStorage.getItem('access')) {
            alert('Please log in');
            return;
        }
        const audioFile = file;
        if (!audioFile) {
            alert('No file is selected');
            return;
        }
        const MAX_SIZE = 10*1024**2;
        if (audioFile.size > MAX_SIZE) {
            alert('File size exceeds the allowed limit (10 MB)');
            return;
        }
        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('topic', topic);
        setUploadStarted(true);
    
        processAudio(formData, setUploadStarted, null, navigate);
    }
  
    return (
        <div>
            <span>Your response should be about 45 seconds long</span>
            <br />
            <br />
            <input type="file" id="actual-btn" accept=".m4a,.mp3,.mp4,.wav" onChange={handleFileUpload} hidden/>
            <label className='btn btn-primary' htmlFor="actual-btn">Choose File</label>
            <br />
            <span id="file-chosen">{file ? file.name : "No file chosen"}</span>
            <br />
            <button className='btn btn-secondary' disabled={!file} onClick={() => uploadAudio(file)}>Upload</button>
        </div>
    );
};

function UploadMode({ topic_id }) {
    const [topic, setTopic] = useState('' + topic_id);
    const [topics, setTopics] = useState([]);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [countUpSeconds, setCountUpSeconds] = useState(0);
    const intervalCountUpRef = useRef();
    
    const fetchTopics = async () => {
        const response = await axios.get(API_URL + '/topic/all');
        setTopics(response.data);
    };

    // Fetch topics on component mount
    useEffect(() => {
        fetchTopics();
    }, []);

    useEffect(() => {
        if (uploadStarted) {
            intervalCountUpRef.current = setInterval(() => {
                setCountUpSeconds((countUpSeconds) => {
                    return countUpSeconds + 1;
                });
            }, 1000);
        } else {
          if (intervalCountUpRef.current) {
            clearInterval(intervalCountUpRef.current);
          }
        }
    
        return () => {
          if (intervalCountUpRef.current) {
            clearInterval(intervalCountUpRef.current);
          }
        };
    }, [uploadStarted]);

    const processingSteps = [
        "Evaluating your grammar...",
        "Checking coherence...",
        "Assessing logic...",
        "Analyzing word choice...",
        "Reviewing your examples...",
        "Testing pronunciation..."
        ];
      const [currentStep, setCurrentStep] = useState(0);
      useEffect(() => {
        if (uploadStarted) {
            const interval = setInterval(() => {
                setCurrentStep(prev => (prev + 1) % processingSteps.length);
            }, (20000 / processingSteps.length)); // Changes message every fraction of the total time
    
            return () => clearInterval(interval);
        }
    }, [uploadStarted]);

    return (
        
        <section className="section section-0 has-shape my-auto">
            <div className="nk-shape bg-shape-blur-d start-50 bottom-0"></div>
            <div className="nk-shape bg-shape-blur-e mt-n12 me-n10 end-50"></div>
            <div className="container">
                <div className="section-content">
                    <div className="row text-center g-gs justify-content-center">
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6">{topics.length >= 1 ? topics[(topic_id - 1)].text : null}</Typography>
                        </div>
                        {uploadStarted &&
                      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <CircularProgress size={60} />
                          <Typography variant="h6" style={{ marginTop: 15 }}>
                              {processingSteps[currentStep]}
                          </Typography>
                          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
                              Please wait... {countUpSeconds}s/30s
                          </Typography>
                      </div>}
                        {topics.length >= 1 && 
                        <div style={uploadStarted ? {display: 'none'} : null}>
                            <FileUploadButtons topic={topic} setUploadStarted={setUploadStarted} />
                        </ div>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UploadMode;

import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import User, { handleLogout } from './Auth/User.js';

function Header(){
    const [fix, setFix] = useState("");
    const [mobileMode, setMobileMode] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showMobileOverlay, setShowMobileOverlay] = useState(false);

    const Break = { mb: 420, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400, any: Infinity };
    const body = document.querySelector('body');
    const breakMain = body.dataset.menuCollapse ? Break[body.dataset.menuCollapse] : Break.lg;

    const navigate = useNavigate();

    const hideMobileMenu = () => {
        setShowMobileMenu(false);
        setShowMobileOverlay(false);
    };

    const mobile = () => {
        if (breakMain < window.innerWidth) {
            setMobileMode(false);
        } else {
            setMobileMode(true);
        }
    };

    const mouseUp = (e) => {
        if (e.target.closest(`.nk-header-menu`) === null) {
            hideMobileMenu();
        }
    };

    const resize = (e) => {
        if (breakMain < window.innerWidth) {
            hideMobileMenu();
        }
        mobile();
    };

    useEffect(() => {
        window.addEventListener('scroll', has_fixed);
        window.addEventListener("resize", resize);
        document.addEventListener("mouseup", mouseUp);
        mobile();
        return () => {
            window.removeEventListener('scroll', has_fixed);
            window.removeEventListener("resize", resize);
            document.removeEventListener("mouseup", mouseUp);
        };
    });

    const has_fixed = () => {
        const fixClass = window.scrollY > 0 ? "has-fixed" : "";
        setFix(fixClass);
    };

    useEffect(() => {
        if (showMobileMenu) {
            setShowMobileOverlay(true);
        }
        else if (showMobileOverlay) {
            setTimeout(() => {
                setShowMobileOverlay(false);
            }, 400)
        }
    }, [showMobileMenu]);

    const handleClick = () => {
        handleLogout();
        navigate('/mock');
    };

    return(
        <header className={"nk-header has-shape" + (showMobileOverlay ? " header-menu-shown" : "")}>
            <div className="nk-shape bg-shape-blur-c ms-n20p mt-n20p start-50 translate-middle-x"></div>
            <div className={"nk-header-main nk-menu-main will-shrink has-border ignore-mask " + fix}>
                <div className="container">
                    <div className="nk-header-wrap">
                        {/* <div className="nk-header-logo">
                            <a href="index.html" className="logo-link">
                                <div className="logo-wrap">
                                    <img className="logo-img logo-light" src="images/logo-green.png" srcSet="images/logo-green2x.png 2x" alt=""/>
                                    <img className="logo-img logo-dark" src="images/logo-green-dark.png" srcSet="images/logo-green-dark2x.png 2x" alt=""/>
                                </div>
                            </a>
                        </div> */}
                        <div className="nk-header-toggle">
                            {/* <button className="dark-mode-toggle">
                                <em className="off icon ni ni-sun-fill"></em>
                                <em className="on icon ni ni-moon-fill"></em>
                            </button> */}
                            <button className={"btn btn-light btn-icon header-menu-toggle" + (showMobileMenu ? ' active' : "")}
                            onClick={() => setShowMobileMenu(true)}>
                                <em className="icon ni ni-menu"></em>
                            </button>
                        </div>
                        {showMobileOverlay ? <div className="header-menu-overlay"></div> : null}
                        {sessionStorage.getItem('isDemo') == null? <nav className={"nk-header-menu nk-menu" +
                                        (mobileMode ? ' menu-mobile' : "") +
                                        (showMobileMenu ? ' header-menu-active' : "")}>
                            <ul className="nk-menu-item mx-auto">
                                <li className="nk-menu-item has-dropdown">
                                    <Link to ="/" className="nk-menu-link">
                                        <span className="nk-menu-text">Return to Landing Page</span>
                                    </Link>
                                </li>
                            </ul>
                            <ul className="nk-menu-buttons flex-lg-row-reverse">
                                <li><User/></li>
                            </ul>
                        </nav>: <nav className={"nk-header-menu nk-menu" +
                                        (mobileMode ? ' menu-mobile' : "") +
                                        (showMobileMenu ? ' header-menu-active' : "")}>
                            <ul className="nk-menu-item mx-auto">
                                <div onClick={handleClick} className="nk-menu-link" style={{ cursor: 'pointer' }}>
                                    恭喜你完成了教程！点我练习更多
                                </div>
                            </ul>
                        </nav>}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
